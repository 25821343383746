<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Data Kategori Posisi
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/katposisi-tambah">
          <b-button variant="primary" class="btn btn-add btn-sm mb-2 px-4">
            Tambah Kategori Posisi</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body" v-if="!loading">
            <div class="row">
              <div class="col-sm-9">
                <b>Kategori Posisi</b>
              </div>
              <!-- Search -->
              <div class="col-1 text-end p-0 mt-2"><b>Tipe</b></div>
              <div class="col-sm-2 text-right">
                <select
                  class="form-control"
                  style="width: 100%"
                  v-model="tipe_posisi"
                  @change="listKategori"
                >
                  <option value="all">Semua</option>
                  <option value="pemain">Pemain</option>
                  <option value="ofisial">Ofisial</option>
                </select>
              </div>
              <!-- End search -->
            </div>

            <div class="table-responsive mt-2" v-if="tableData.length > 0">
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <!-- <th width="8%" class="text-center">Foto</th> -->
                    <th>Nama Posisi</th>
                    <th width="20%">Tipe</th>
                    <th class="text-center" width="10%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in tableData" :key="idx">
                    <td class="text-center">
                      {{ idx + 1 }}
                    </td>
                    <td>
                      {{ list.nama_posisi }}
                    </td>
                    <td>
                      {{ list.tipe }}
                    </td>

                    <td class="text-center">
                      <span class="mr-1 bg-success m-1">
                        <router-link :to="'katposisi-ubah?idk=' + list.id">
                          <b-button
                            title="Edit Skuad"
                            class="btn btn-sm px-2 py-1"
                            variant="secondary"
                            ><i class="fa fa-pencil"></i
                          ></b-button>
                        </router-link>
                      </span>
                      <span class="mr-1">
                        <b-button
                          @click="deleteKategori(list.id, list.nama_posisi)"
                          title="Delete Skuad"
                          class="btn btn-sm px-2 py-1"
                          variant="danger"
                          ><i class="fa fa-trash"></i
                        ></b-button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive mt-2" v-else>
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <!-- <th width="8%" class="text-center">Foto</th> -->
                    <th>Nama Posisi</th>
                    <th width="20%">Tipe</th>
                    <th class="text-center" width="10%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="7" class="text-center">Data tidak ada!</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div class="">
                <span>
                  Total data : <b>{{ tableData.length }}</b>
                </span>
              </div>
            </div>
          </div>

          <div class="card-body" v-else>
            <div class="row">
              <div class="col-12 py-5 my-5">
                <div v-if="loading" class="mt-5 mx-auto p-5">
                  <div class="text-center">
                    <div
                      class="spinner-border text-secondary"
                      otp_ocr="status"
                      v-if="loading"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import KategoriService from "@/_services/kategori.service";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      url_img: process.env.VUE_APP_API_URL_IMAGE,
      search: "",
      loading: false,
      isEdit: false,
      tableData: [],
      tipe_posisi: "all",
      params: "",
      form: {
        id: "",
        nama_posisi: "",
        tipe: "",
      },

      submitted: false,
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },

  methods: {
    listKategori() {
      this.loading = true;
      KategoriService.getPosisi(this.tipe_posisi)
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data;
          // console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id,
              nama_posisi: val.nama_posisi,
              tipe: val.tipe,
              created_at: val.created_at,
            };
            this.tableData.push(data);
          });

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    listKategoriReload() {
      KategoriService.getPosisi(this.tipe_posisi)
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data;
          // console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id,
              nama_posisi: val.nama_posisi,
              tipe: val.tipe,
              created_at: val.created_at,
            };
            this.tableData.push(data);
          });

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    deleteKategori(id, name) {
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus data ini?",
        text: name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#153F37",
        cancelButtonColor: "#eeeeee",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("id", id);
          KategoriService.deletePosisi(id)
            .then((response) => {
              this.listKategoriReload();
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log("err ", err);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Gagal",
                text: "Hapus kategori Gagal",
                showConfirmButton: false,
                timer: 2500,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
    this.listKategori();
  },
};
</script>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
