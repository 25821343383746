import icon from "../../data/icon.json"

const state = {
    feather_icons : icon.feather_icons
};

export default {
    namespaced: true,
    state,
}
