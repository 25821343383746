<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/jadwal">Jadwal</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Tambah Jadwal Baru
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/jadwal">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Data Jadwal Pertandingan</h6>
          </div>
          <hr />
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-2 position-relative">
                <label class="mb-1" for="validationTooltip01"
                  >Tanggal Pertandingan</label
                >
                <input
                  type="date"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? tanggalError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="tanggal"
                  placeholder="tanggal"
                  v-model="tanggal"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[0] && formSubmitted"
                >
                  {{ errors[0].message }}
                </small>
              </div>
              <div class="col-md-2 position-relative">
                <label class="mb-1" for="validationTooltip02"
                  >Jam Kickoff</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted ? (jamError ? 'is-invalid' : 'is-valid') : ''
                  "
                  id="jam"
                  placeholder="jam"
                  v-model="jam"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[1] && formSubmitted"
                >
                  {{ errors[1].message }}
                </small>
              </div>
              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip03"
                  >Tipe Pertandingan</label
                >
                <select
                  type="text"
                  class="form-control"
                  id="tipe_pertandingan"
                  v-model="tipe_pertandingan"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? tipe_pertandinganError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">- Tipe Pertandingan -</option>
                  <option value="persahabatan">Persahabatan</option>
                  <option value="turnamen">Turnamen</option>
                  <option value="liga">Liga</option>
                  <option value="lainya">Lainya</option>
                </select>
                <small class="text-danger" v-if="errors[5] && formSubmitted">
                  {{ errors[5].message }}
                </small>
              </div>
              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip04">Status </label>
                <select
                  type="text"
                  class="form-control"
                  id="status"
                  v-model="status"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? statusError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">- Status Pertandingan -</option>
                  <option value="terjadwal">Terjadwal</option>
                  <option value="selesai">Selesai</option>
                  <option value="ditunda">Ditunda</option>
                  <option value="dibatalkan">Dibatalkan</option>
                </select>
                <small class="text-danger" v-if="errors[4] && formSubmitted">
                  {{ errors[4].message }}
                </small>
              </div>

              <!-- ---- -->
              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip05">Skuad</label>
                <select
                  type="text"
                  class="form-control"
                  id="skuad"
                  v-model="skuad"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? skuadError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">- Pilih Skuad -</option>
                  <option
                    :value="list.id"
                    v-for="list in skuad_list"
                    :key="list.id"
                  >
                    {{ list.nama_skuad }}
                  </option>
                </select>
                <small class="text-danger" v-if="errors[3] && formSubmitted">
                  {{ errors[3].message }}
                </small>
              </div>

              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip06"
                  >Nama Tim Lawan</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? tim_lawanError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="tim_lawan"
                  placeholder="nama tim lawan"
                  v-model="tim_lawan"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[7] && formSubmitted"
                >
                  {{ errors[7].message }}
                </small>
              </div>

              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip07"
                  >Tempat Pertandingan</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? tempatError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="tempat"
                  placeholder="tempat pertandingan"
                  v-model="tempat"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[2] && formSubmitted"
                >
                  {{ errors[2].message }}
                </small>
              </div>

              <div
                class="col-md-2 position-relative"
                v-if="status == 'selesai'"
              >
                <label class="mb-1" for="validationTooltip09">Hasil</label>
                <select
                  type="text"
                  class="form-control"
                  id="hasil"
                  v-model="hasil"
                >
                  <option value="">- Hasil Pertandingan -</option>
                  <option value="menang">Menang</option>
                  <option value="seri">Seri</option>
                  <option value="kalah">Kalah</option>
                </select>
              </div>
              <div
                class="col-md-2 position-relative"
                v-if="status == 'selesai'"
              >
                <label class="mb-1" for="validationTooltip01">Skor Tim</label>
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  id="skor"
                  placeholder="skor"
                  v-model="skor"
                />
              </div>
              <div
                class="col-md-2 position-relative"
                v-if="status == 'selesai'"
              >
                <label class="mb-1" for="validationTooltip10">Skor Lawan</label>
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  id="skor_lawan"
                  placeholder="skor lawan"
                  v-model="skor_lawan"
                />
              </div>

              <div
                class="col-md-3 position-relative"
                v-if="status == 'selesai'"
              >
                <label class="mb-1" for="validationTooltip01"
                  >Pencetak Gol</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  id="pencetak_gol"
                  placeholder="pencetak gol"
                  v-model="pencetak_gol"
                />
              </div>
              <div
                class="col-md-3 position-relative"
                v-if="status == 'selesai'"
              >
                <label class="mb-1" for="validationTooltip10"
                  >Pencetak Gol Lawan</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  id="pencetak_gol_lawan"
                  placeholder="pencetak gol lawan"
                  v-model="pencetak_gol_lawan"
                />
              </div>

              <div class="col-md-12 position-relative">
                <label class="mb-1" for="validationTooltip11">Keterangan</label>
                <input
                  type="text"
                  class="form-control"
                  id="keterangan"
                  placeholder="Keterangan"
                  v-model="keterangan"
                />
              </div>

              <div class="col-md-4 position-relative">
                <b for="formFileSm" class="">Logo Tim Lawan</b>
                <input
                  class="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  @change="imagejadwal"
                  accept="image/*"
                  v-bind:class="
                    formSubmitted ? (!images ? 'is-invalid' : 'is-valid') : ''
                  "
                />
                <small class="text-danger" v-if="!images && formSubmitted">
                  logo harus di isi !
                </small>
                <div>
                  <img
                    :src="previewUrl"
                    class="rounded mr-4 mt-4 w-20"
                    width="100"
                  />
                </div>
              </div>
              <div class="col-12 text-end">
                <button class="btn btn-light" @click="resetForm" type="button">
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanJadwal"
                  type="button"
                >
                  <span v-if="loading">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                    Loading ..
                  </span>
                  <span v-else> Simpan </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import jadwalService from "@/_services/jadwal.service";
import SkuadService from "@/_services/skuad.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      skuad_list: [],
      images: "",
      previewUrl: null,
      imagesError: false,
      skuad: "",
      status: "",
      statusError: false,
      tim_lawan: "",
      tim_lawanError: false,
      tanggal: "",
      tanggalError: false,
      jam: "",
      jamError: false,
      tipe_pertandingan: "",
      tipe_pertandinganError: false,

      hasil: "",
      tempat: "",
      skor: "",
      skor_lawan: "",
      pencetak_gol: "",
      pencetak_gol_lawan: "",
      keterangan: "",
      validation: false,
      errors: [],
      formSubmitted: false,
      editor: ClassicEditor,
    };
  },

  methods: {
    imagejadwal(evt) {
      this.images = evt.target.files[0];
      this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.tanggal.length < 1) {
        this.tanggalError = true;
        this.errors.push({
          message: "tanggal harus di isi !",
        });
      } else {
        this.tanggalError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.jam.length < 1) {
        this.jamError = true;
        this.errors.push({
          message: "jam harus di isi !",
        });
      } else {
        this.jamError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.tempat.length < 1) {
        this.tempatError = true;
        this.errors.push({
          message: "tempat harus di isi !",
        });
      } else {
        this.tempatError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.skuad.length < 1) {
        this.skuadError = true;
        this.errors.push({
          message: "skuad harus di isi !",
        });
      } else {
        this.skuadError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.status.length < 1) {
        this.statusError = true;
        this.errors.push({
          message: "status harus di isi !",
        });
      } else {
        this.statusError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.tipe_pertandingan.length < 1) {
        this.tipe_pertandinganError = true;
        this.errors.push({
          message: "tipe pertandingan harus di isi !",
        });
      } else {
        this.tipe_pertandinganError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.tim_lawan.length < 1) {
        this.tim_lawanError = true;
        this.errors.push({
          message: "tim lawan harus di isi !",
        });
      } else {
        this.tim_lawanError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.tim_lawan.length < 1) {
        this.tim_lawanError = true;
        this.errors.push({
          message: "tim lawan harus di isi !",
        });
      } else {
        this.tim_lawanError = false;
        this.errors.push({
          message: "",
        });
      }

      if (this.images.length < 1) {
        this.imagesError = true;
        this.errors.push({
          message: "logo lawan harus di isi !",
        });
      } else {
        this.imagesError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanJadwal() {
      this.formSubmitted = true;
      this.validationForm();

      if (
        this.tim_lawan &&
        this.status &&
        this.images &&
        this.tanggal &&
        this.jam
      ) {
        this.loading = true;
        this.validation = true;

        const formData = new FormData();
        formData.append("status", this.status);
        formData.append("skuad_id", this.skuad);
        formData.append("tim_lawan", this.tim_lawan);
        formData.append("hasil", this.hasil);
        formData.append("tanggal", this.tanggal);
        formData.append("jam", this.jam);
        formData.append("tempat", this.tempat);
        formData.append("skor", this.skor);
        formData.append("skor_lawan", this.skor_lawan);
        formData.append("pencetak_gol", this.pencetak_gol);
        formData.append("pencetak_gol_lawan", this.pencetak_gol_lawan);
        formData.append("tipe_pertandingan", this.tipe_pertandingan);
        formData.append("keterangan", this.keterangan);
        formData.append("image", this.images);

        // console.log("fd", FormData);
        jadwalService
          .add(formData)
          .then((response) => {
            // console.log("berhasil", response.data);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Data Berhasil Ditambah",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading = false;
            this.$router.push("/pages/jadwal");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Data Gagal Ditambah",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    listSkuad() {
      SkuadService.dropdown()
        .then((response) => {
          this.skuad_list = [];
          let data_tim = response.data.data;
          // console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              id: val.id,
              nama_skuad: val.nama_skuad,
            };
            this.skuad_list.push(data);
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    resetForm() {
      // console.log("rseset ");
      this.loading = false;
      this.images = "";
      this.previewUrl = null;
      this.imagesError = false;
      this.errors = [];
      this.formSubmitted = false;
      this.tim_lawan = "";
      this.tim_lawanError = false;
      this.status = "";
      this.tanggalError = false;
      this.jam = "";
      this.hasil = "";
      this.jamError = false;
      this.tipe_pertandingan = "";
      this.tempat = "";
      this.skor = "";
      this.skor_lawan = "";
      this.pencetak_gol = "";
      this.pencetak_gol_lawan = "";
      this.keterangan = "";
      this.skuad_id = "";
      this.validation = false;
    },
  },
  mounted() {
    this.listSkuad();
  },
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
