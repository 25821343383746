<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/pemain">Pemain</a></li>
            <li class="breadcrumb-item">
              <a :href="'/pages/pemain-detail?idp=' + id">Detail Pemain</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit Pemain
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link :to="'/pages/pemain-detail?idp=' + id">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <!-- <div class="card-header pb-0">
            <h6>Ubah Data Pemain</h6>
          </div>
          <hr /> -->
          <div class="card-body mt-2">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip03">Skuad Tim</label>
                <select
                  type="text"
                  class="form-control"
                  id="team_skuad_id"
                  v-model="form.team_skuad_id"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.team_skuad_idError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">- Pilih Skuad -</option>
                  <option
                    :value="list.id"
                    v-for="list in skuad_list"
                    :key="list.id"
                  >
                    {{ list.nama_skuad }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-if="formErorr.team_skuad_idError && formSubmitted"
                >
                  {{ formErorr.team_skuad_idError }}
                </small>
              </div>

              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip02"
                  >Nama Pemain</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.namaError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="nama"
                  placeholder="nama"
                  v-model="form.nama"
                />
                <small
                  class="align-end text-danger"
                  v-if="formErorr.namaError && formSubmitted"
                >
                  {{ formErorr.namaError }}
                </small>
              </div>

              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip02"
                  >Tempat Lahir</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.tempat_lahirError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="tempat_lahir"
                  placeholder="tempat lahir"
                  v-model="form.tempat_lahir"
                />
                <small
                  class="align-end text-danger"
                  v-if="formErorr.tempat_lahirError && formSubmitted"
                >
                  {{ formErorr.tempat_lahirError }}
                </small>
              </div>

              <div class="col-md-2 position-relative">
                <label class="mb-1" for="validationTooltip01"
                  >Tanggal Lahir</label
                >
                <input
                  type="date"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.tanggal_lahirError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="tanggal_lahir"
                  placeholder="tanggal_lahir"
                  v-model="form.tanggal_lahir"
                />
                <small
                  class="align-end text-danger"
                  v-if="formErorr.tanggal_lahirError && formSubmitted"
                >
                  {{ formErorr.tanggal_lahirError }}
                </small>
              </div>

              <div class="col-md-2 position-relative">
                <label class="mb-1" for="validationTooltip11">Umur</label>
                <input
                  type="text"
                  class="form-control"
                  id="umur"
                  placeholder="umur"
                  v-model="form.umur"
                />
              </div>

              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip04">Posisi</label>
                <select
                  type="text"
                  class="form-control"
                  id="posisi"
                  v-model="form.posisi"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.posisiError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">- Pilih Posisi -</option>
                  <option
                    :value="list.id"
                    v-for="list in posisi_list"
                    :key="list.id"
                  >
                    {{ list.nama_posisi }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-if="formErorr.posisiError && formSubmitted"
                >
                  {{ formErorr.posisiError }}
                </small>
              </div>

              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip11"
                  >No Punggung</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="no_punggung"
                  placeholder="no punggung"
                  v-model="form.no_punggung"
                />
              </div>

              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip11">Wali Murid</label>
                <input
                  type="text"
                  class="form-control"
                  id="wali_murid"
                  placeholder="wali murid"
                  v-model="form.wali_murid"
                />
              </div>

              <div class="col-md-8 position-relative">
                <label class="mb-1" for="validationTooltip11">Keterangan</label>
                <input
                  type="text"
                  class="form-control"
                  id="keterangan"
                  placeholder="Keterangan"
                  v-model="form.keterangan"
                />
              </div>

              <div class="col-md-4 position-relative">
                <b for="formFileSm" class="">Foto Pemain</b>
                <input
                  class="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  @change="imageShow"
                  accept="image/*"
                />
                <div>
                  <img
                    :src="previewUrl"
                    class="rounded mr-4 mt-4 w-20"
                    width="100"
                  />
                </div>
              </div>
              <div class="col-12 text-end">
                <button class="btn btn-light" @click="resetForm" type="button">
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanPemain"
                  type="button"
                >
                  <span v-if="loading">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="tempat_lahir"
                    >
                      <span class="sr-only"></span>
                    </div>
                    Loading ..
                  </span>
                  <span v-else> Simpan </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h6>Statistik Pemain</h6>
            <form class="g-3 needs-validation mt-3 p-4" novalidate="">
              <div class="row border mb-3 p-4 bg-card-stats">
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Kaki Dominan</label
                  >
                  <select
                    name="foot_dominant"
                    id="foot_dominant"
                    class="form-control"
                    v-model="stats.foot_dominant"
                  >
                    <option value="Kanan">Kanan</option>
                    <option value="Kanan">Kiri</option>
                  </select>
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Tinggi (Cm)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="height"
                    v-model="stats.height"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Berat (Kg)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="weight"
                    v-model="stats.weight"
                  />
                </div>

                <!-- <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Nama Wali Murid</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="weight"
                    placeholder="weight"
                    v-model="stats.student_guardiant"
                  />
                </div> -->

                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Disiplin (Discipline)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="height"
                    v-model="stats.k_discipline"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Motivasi (Motivation)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="height"
                    v-model="stats.k_motivation"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Kerjasama (Tim Working)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="height"
                    v-model="stats.k_teamwork"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Mental (Mentality)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="k_mental"
                    v-model="stats.k_mental"
                  />
                </div>

                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11">Respect</label>
                  <input
                    type="number"
                    class="form-control"
                    id="k_respect"
                    v-model="stats.k_respect"
                  />
                </div>
              </div>

              <div class="row border mb-3 p-4 bg-card-stats">
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Akurasi Tembakan (Shoot)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_shoot"
                    v-model="stats.s_shoot"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Akurasi Umpan (Passing)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_passing"
                    v-model="stats.s_passing"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Mengiring Bola (Dribble)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_dribble"
                    v-model="stats.s_dribble"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Sundulan (Header)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_head"
                    v-model="stats.s_head"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Bertahan (Defance)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_defence"
                    v-model="stats.s_defence"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Menyerang (Attack)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_attack"
                    v-model="stats.s_attack"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Mobilitas (Mobility)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="height"
                    v-model="stats.s_mobility"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11">Reflek</label>
                  <input
                    type="number"
                    class="form-control"
                    id="height"
                    v-model="stats.s_mobility"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Lompatan (Jumping)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="height"
                    v-model="stats.s_mobility"
                  />
                </div>
              </div>

              <div class="row border mb-3 p-4 bg-card-stats">
                <div class="col-md-6 mb-3">
                  <label class="mb-1" for="validationTooltip41"
                    >Turnamen diikuti</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="tournament"
                    v-model="stats.tournament"
                  />
                </div>

                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip42"
                    >Main diturnamen</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_main_tournament"
                    v-model="stats.s_main_tournament"
                  />
                </div>

                <div class="col-md-3 mb-3">
                  <label class="mb-1" for="validationTooltip43"
                    >Gol diturnamen</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="s_goal_tournament"
                    v-model="stats.s_goal_tournament"
                  />
                </div>

                <div class="col-md-12 mb-3">
                  <label class="mb-1" for="validationTooltip11"
                    >Catatan Pelatih
                  </label>
                  <textarea
                    type="text"
                    class="form-control"
                    id="note"
                    v-model="stats.note_coach"
                    rows="3"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-end px-0">
                  <!-- <button class="btn btn-light" @click="resetForm" type="button">
                  Reset
                </button> -->
                  <button
                    class="btn btn-primary px-4 mx-0"
                    @click="updateStatistik"
                    type="button"
                  >
                    <span v-if="loading_stats">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="tempat_lahir"
                      >
                        <span class="sr-only"></span>
                      </div>
                      Loading ..
                    </span>
                    <span v-else> Simpan </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PemainService from "@/_services/pemain.service";
import SkuadService from "@/_services/skuad.service";
import PosisiService from "@/_services/posisi.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.idp,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      loading_stats: false,
      skuad_list: [],
      posisi_list: [],
      form: {
        team_skuad_id: "",
        nama: "",
        umur: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        posisi: "",
        no_punggung: "",
        wali_murid: "",
        keterangan: "",
      },
      stats: {
        pemain_id: "",
        height: "",
        weight: "",
        stamina: "",
        condition: "",
        foot_dominant: "",
        k_discipline: "",
        k_teamwork: "",
        k_mental: "",
        k_motivation: "",
        k_respect: "",
        s_passing: "",
        s_speed: "",
        s_mobility: "",
        s_dribble: "",
        s_shoot: "",
        s_speed: "",
        s_defence: "",
        s_attack: "",
        s_head: "",
        s_tackle: "",
        s_position: "",
        s_gk_reflex: "",
        s_gk_jump: "",
        s_goal_tournament: "",
        s_main_tournament: "",
        note_coach: "",
        tournament: "",
        etc: "",
      },
      formErorr: {
        namaError: false,
        team_skuad_idError: false,
        posisiError: false,
        tempat_lahirError: false,
        tanggal_lahirError: false,
        tempat_lahirError: false,
      },
      images: "",
      previewUrl: null,
      imagesError: false,
      validation: false,
      errors: [],
      formSubmitted: false,
      editor: ClassicEditor,
    };
  },

  methods: {
    imageShow(evt) {
      this.images = evt.target.files[0];
      this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.form.nama.length < 1) {
        this.formErorr.namaError = "nama harus di isi !";
      } else {
        this.formErorr.namaError = false;
      }

      if (this.form.team_skuad_id.length < 1) {
        this.formErorr.team_skuad_idError = "team skuad harus di isi !";
      } else {
        this.formErorr.team_skuad_idError = false;
      }

      if (this.form.tanggal_lahir.length < 1) {
        this.formErorr.tanggal_lahirError = "tanggal lahir harus di isi !";
      } else {
        this.formErorr.tanggal_lahirError = false;
      }

      if (this.form.tempat_lahir.length < 1) {
        this.formErorr.tempat_lahirError = "tempat lahir harus di isi !";
      } else {
        this.formErorr.tempat_lahirError = false;
      }

      if (this.form.posisi.length < 1) {
        this.formErorr.posisiError = "posisi harus di isi !";
      } else {
        this.formErorr.posisiError = false;
      }

      if (this.images.length < 1) {
        this.imagesError = true;
        this.errors.push({
          message: "foto pemain harus di isi !",
        });
      } else {
        this.imagesError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanPemain() {
      this.formSubmitted = true;
      this.validationForm();

      if (
        this.form.tanggal_lahir &&
        this.form.team_skuad_id &&
        this.form.posisi &&
        this.form.nama
      ) {
        this.loading = true;
        this.validation = true;

        if (this.images) {
          const formData = new FormData();
          formData.append("team_skuad_id", this.form.team_skuad_id);
          formData.append("nama", this.form.nama);
          formData.append("tempat_lahir", this.form.tempat_lahir);
          formData.append("tanggal_lahir", this.form.tanggal_lahir);
          formData.append("no_punggung", this.form.no_punggung);
          formData.append("umur", this.form.umur);
          formData.append("posisi", this.form.posisi);
          formData.append("wali_murid", this.form.wali_murid);
          formData.append("keterangan", this.form.keterangan);
          formData.append("img", this.images);

          // console.log("fd", FormData);
          PemainService.update(this.id, formData)
            .then((response) => {
              // console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              // this.$router.push("/pages/pemain");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          const formData = new FormData();
          formData.append("team_skuad_id", this.form.team_skuad_id);
          formData.append("nama", this.form.nama);
          formData.append("tempat_lahir", this.form.tempat_lahir);
          formData.append("tanggal_lahir", this.form.tanggal_lahir);
          formData.append("no_punggung", this.form.no_punggung);
          formData.append("umur", this.form.umur);
          formData.append("posisi", this.form.posisi);
          formData.append("wali_murid", this.form.wali_murid);
          formData.append("keterangan", this.form.keterangan);

          // console.log("fd no image", FormData);
          PemainService.update(this.id, formData)
            .then((response) => {
              // console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              //  this.$router.push("/pages/pemain");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      } else {
        // console.log("validation failed");
      }
    },

    updateStatistik() {
      this.loading_stats = true;
      PemainService.updateStats(this.stats)
        .then((response) => {
          this.loading_stats = false;
          // console.log("berhasil stats update", response.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Data Berhasil Diupdate",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading_stats = false;
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Data Gagal Diupdate",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },

    listSkuad() {
      SkuadService.dropdown()
        .then((response) => {
          this.skuad_list = [];
          let data_tim = response.data.data;
          // console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              id: val.id,
              nama_skuad: val.nama_skuad,
            };
            this.skuad_list.push(data);
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    listPosisi() {
      PosisiService.dropdownPemain()
        .then((response) => {
          this.posisi_list = [];
          let list_data = response.data.data;
          // console.log("data", response.data.data);
          list_data.forEach((val, index) => {
            const data = {
              id: val.id,
              nama_posisi: val.nama_posisi,
            };
            this.posisi_list.push(data);
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    detailDetail() {
      // this.loading = true;
      PemainService.detail(this.id)
        .then((response) => {
          const val = response.data.data;
          const vals = response.data.data.stats;

          this.previewUrl = this.urlImage + val.img;
          this.form.team_skuad_id = val.team_skuad_id;
          this.form.nama = val.nama;
          this.form.tempat_lahir = val.tempat_lahir;
          this.form.tanggal_lahir = val.tanggal_lahir;
          this.form.umur = val.umur;
          this.form.posisi = val.posisi.id;
          this.form.no_punggung = val.no_punggung;
          this.form.keterangan = val.keterangan;
          this.form.wali_murid = val.wali_murid;
          this.loading = false;

          console.log("data", vals);
          this.stats.pemain_id = vals.pemain_id;
          this.stats.height = vals.height;
          this.stats.weight = vals.weight;
          this.stats.stamina = vals.stamina;
          this.stats.condition = vals.condition;
          this.stats.foot_dominant = vals.foot_dominant;
          this.stats.student_guardiant = vals.student_guardiant;
          this.stats.k_discipline = vals.k_discipline;
          this.stats.k_teamwork = vals.k_teamwork;
          this.stats.k_mental = vals.k_mental;
          this.stats.k_motivation = vals.k_motivation;
          this.stats.k_respect = vals.k_respect;
          this.stats.s_passing = vals.s_passing;
          this.stats.s_speed = vals.s_speed;
          this.stats.s_mobility = vals.s_mobility;
          this.stats.s_dribble = vals.s_dribble;
          this.stats.s_shoot = vals.s_shoot;
          this.stats.s_speed = vals.s_speed;
          this.stats.s_defence = vals.s_defence;
          this.stats.s_attack = vals.s_attack;
          this.stats.s_head = vals.s_head;
          this.stats.s_tackle = vals.s_tackle;
          this.stats.s_position = vals.s_position;
          this.stats.s_gk_reflex = vals.s_gk_reflex;
          this.stats.s_gk_jump = vals.s_gk_jump;
          this.stats.s_goal_tournament = vals.s_goal_tournament;
          this.stats.s_main_tournament = vals.s_main_tournament;
          this.stats.note_coach = vals.note_coach;
          this.stats.tournament = vals.tournament;
          this.stats.etc = vals.etc;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    resetForm() {
      console.log("reset ");
      this.loading = false;
      this.images = "";
      this.previewUrl = null;
      this.imagesError = false;
      this.errors = [];
      this.formSubmitted = false;
      this.validation = false;
      this.formErorr = {
        namaError: false,
        team_skuad_idError: false,
        posisiError: false,
        tempat_lahirError: false,
        tanggal_lahirError: false,
        tempat_lahirError: false,
      };
      this.detailDetail();
    },
  },
  mounted() {
    this.listSkuad();
    this.listPosisi();
    this.detailDetail();
  },
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.bg-card-stats {
  background-color: #f5f5f5;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
