<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Data Jadwal
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/jadwal-tambah">
          <b-button variant="primary" class="btn btn-add btn-sm mb-2 px-4">
            Tambah Jadwal</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body" v-if="!loading">
            <div class="row">
              <div class="col-sm-6 col-md-9 mt-2">
                <div class="d-flex align-items-center mr-2">
                  Tampilkan : &nbsp;
                  <select
                    class="form-control"
                    style="width: 70px"
                    v-model="pagination.per_page"
                    @change="listJadwal(pagination.curr_page)"
                  >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-3 mt-2">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control pr-1"
                    placeholder="Cari Jadwal.."
                    v-model="form.search"
                    @change="listJadwalSearch(1)"
                    @keyup.enter="listJadwalSearch(1)"
                  />
                  <span>
                    <button
                      class="btn btn-sm btn-primary h-100"
                      @click="listJadwal(1)"
                    >
                      <i class="fas fa-search-plus px-1" v-if="loading"></i>
                      <i class="fa fa-search px-0 m-0" v-else></i>
                    </button>
                  </span>
                </div>
              </div>
              <!-- End search -->
            </div>

            <div class="table-responsive mt-2" v-if="tableData.length > 0">
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <th width="10%">Tanggal</th>
                    <th width="5%">Jam</th>
                    <th width="22%">Tim Skuad</th>
                    <th width="22%">Tim Lawan</th>
                    <th width="15%">Tipe</th>
                    <th>Status</th>
                    <th class="text-center" width="12%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in tableData" :key="idx">
                    <td class="text-center">
                      {{ pagination.from + idx }}
                    </td>
                    <td>
                      {{ list.tanggal }}
                    </td>
                    <td>
                      {{ list.jam }}
                    </td>
                    <td>
                      {{ list.skuad?.nama_skuad }}
                    </td>
                    <td>
                      {{ list.tim_lawan }}
                    </td>
                    <td>{{ list.tipe_pertandingan }}</td>
                    <td>
                      <span
                        class="badge badge-warning rounded"
                        v-if="list.status == 'terjadwal'"
                      >
                        {{ list.status }}</span
                      >
                      <span
                        class="badge badge-info"
                        v-if="list.status == 'ditunda'"
                      >
                        {{ list.status }}</span
                      >
                      <span
                        class="badge badge-danger"
                        v-if="list.status == 'dibatalkan'"
                      >
                        {{ list.status }}</span
                      >
                      <span
                        class="badge badge-primary"
                        v-if="list.status == 'selesai'"
                      >
                        {{ list.status }}</span
                      >
                    </td>
                    <td class="text-center">
                      <span class="mr-1 bg-success">
                        <router-link :to="'jadwal-detail?idj=' + list.id">
                          <b-button
                            title="Detail Jadwal"
                            class="btn btn-sm px-2 py-1"
                            variant="primary"
                            ><i class="fa fa-eye"></i
                          ></b-button>
                        </router-link>
                      </span>
                      <span class="mr-1 bg-success m-1">
                        <router-link :to="'jadwal-ubah?idp=' + list.id">
                          <b-button
                            title="Edit Jadwal"
                            class="btn btn-sm px-2 py-1"
                            variant="secondary"
                            ><i class="fa fa-pencil"></i
                          ></b-button>
                        </router-link>
                      </span>
                      <span class="mr-1">
                        <b-button
                          @click="
                            deleteJadwal(list.id, list.skuad, list.tim_lawan)
                          "
                          title="Delete Jadwal"
                          class="btn btn-sm px-2 py-1"
                          variant="danger"
                          ><i class="fa fa-trash"></i
                        ></b-button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive mt-2" v-else>
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <th width="10%">Tanggal</th>
                    <th width="5%">Jam</th>
                    <th width="22%">Tim Skuad</th>
                    <th width="22%">Tim Lawan</th>
                    <th width="15%">Tipe</th>
                    <th>Status</th>
                    <th class="text-center" width="12%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="7" class="text-center">Data tidak ada!</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div class="">
                <label class="d-inline-flex align-items-center mr-2">
                  Halaman {{ pagination.curr_page }} |
                </label>
                <span>
                  Menampilkan {{ pagination.from }} - {{ pagination.to }} dari
                  {{ pagination.total }} data
                </span>
              </div>
              <ul
                class="pagination pagination-rounded pagination-sm justify-content-end mb-2"
                v-if="pagination.total > 10"
              >
                <li
                  class="page-item"
                  :class="[
                    pg.active ? 'active' : '',
                    pg.url == null ? 'disabled' : 'hoverable',
                  ]"
                  v-for="(pg, pgi) in pagination.links"
                  :key="pgi"
                >
                  <a
                    class="page-link"
                    aria-label="Previous"
                    v-if="pg.label == 'Previous'"
                    @click="listJadwal(pagination.curr_page - 1)"
                  >
                    <i class="fa fa-chevron-left"></i>
                  </a>
                  <a
                    class="page-link"
                    aria-label="Next"
                    v-else-if="pg.label == 'Next'"
                    @click="listJadwal(pagination.curr_page + 1)"
                  >
                    <i class="fa fa-chevron-right"></i>
                  </a>
                  <a class="page-link" v-else-if="pg.label == '...'">
                    {{ pg.label }}
                  </a>
                  <a class="page-link" @click="listJadwal(pg.label)" v-else>
                    {{ pg.label }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-body" v-else>
            <div class="row">
              <div class="col-12 py-5 my-5">
                <div v-if="loading" class="mt-5 mx-auto p-5">
                  <div class="text-center">
                    <div
                      class="spinner-border text-secondary"
                      otp_ocr="status"
                      v-if="loading"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import JadwalService from "@/_services/jadwal.service";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      url_img: process.env.VUE_APP_API_URL_IMAGE,
      search: "",
      loading: false,
      isEdit: false,
      tableData: [],
      pagination: {
        links: [],
        curr_page: 0,
        last_page: 0,
        last_page: 0,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
      },
      params: "",
      form: {
        id: "",
        id: "",
        slug: "",
        skuad: "",
        search: "",
      },

      submitted: false,
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },

  methods: {
    listJadwal(page) {
      this.params = "page=" + page + "&per_page=" + this.pagination.per_page;
      this.loading = true;
      JadwalService.get(this.params)
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data.data;
          // console.log("data", response.data.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id,
              skuad: val.skuad,
              jam: val.jam,
              tim_lawan: val.tim_lawan,
              tipe_pertandingan: val.tipe_pertandingan,
              status: val.status,
              created_at: val.created_at,
              tanggal: val.tanggal,
            };
            this.tableData.push(data);
          });
          this.pagination.links = response.data.data.links;
          this.pagination.curr_page = response.data.data.current_page;
          this.pagination.last_page = response.data.data.last_page;
          this.pagination.per_page = response.data.data.per_page;
          this.pagination.from = response.data.data.from;
          this.pagination.to = response.data.data.to;
          this.pagination.total = response.data.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    listJadwalSearch(page) {
      this.params =
        "search=" +
        this.form.search +
        "&page=" +
        page +
        "&per_page=" +
        this.pagination.per_page;
      this.loading = true;
      // console.log("param", this.params);
      this.loading = true;
      JadwalService.get(this.params)
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data.data;
          // console.log("data", response.data.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id,
              skuad: val.skuad,
              tanggal: val.tanggal,
              tim_lawan: val.tim_lawan,
              status: val.status,
              created_at: val.created_at,
            };
            this.tableData.push(data);
          });
          this.pagination.links = response.data.data.links;
          this.pagination.curr_page = response.data.data.current_page;
          this.pagination.last_page = response.data.data.last_page;
          this.pagination.per_page = response.data.data.per_page;
          this.pagination.from = response.data.data.from;
          this.pagination.to = response.data.data.to;
          this.pagination.total = response.data.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    deleteJadwal(id, name, lawan) {
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus data ini?",
        text: name?.nama_skuad + " vs " + lawan,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#153F37",
        cancelButtonColor: "#eeeeee",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          // console.log("id", id);
          JadwalService.delete(id)
            .then((response) => {
              this.loading = false;
              this.listJadwal(this.pagination.curr_page);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log("err ", err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Gagal",
                text: "Hapus Jadwal Gagal",
                showConfirmButton: false,
                timer: 2500,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
    this.listJadwal(1);
  },
};
</script>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
