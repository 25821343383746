<template>
     <button class="btn btn-primary-light" type="button"><a @click="logout" class="d-flex"><vue-feather type="log-out"></vue-feather>Log out</a></button>
</template>
<script>
import router from '@/router';

export default {
    components:{
       
    },
     methods: {
      logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        router.push('/auth/login');
      },
    },
}
</script>