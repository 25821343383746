<template>
  <div id="app">
    <router-view />
    <div class="loader-wrapper" v-if="showLoader">
      <div class="theme-loader">
        <div class="loader-p"></div>
      </div>
    </div>
  </div>
</template>
<script>
import SettingService from "@/_services/setting.service";
export default {
  data() {
    return {
      showLoader: false,
    };
  },
  watch: {
    $route() {
      this.showLoader = false;
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
    },
  },
  mounted() {
    // this.loading = true;
    SettingService.get(this.id)
      .then((response) => {
        console.log("data", response.data.data);
        const val = response.data.data;
        localStorage.setItem("logo-t", val.img);
        localStorage.setItem("nama-t", val.nama_tim);
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
};
</script>
<style lang="scss">
.text-success {
  color: #24695c;
}
.form-control {
  border: #d0d6db 1.4px solid;
}

.form-control:focus {
  border: #24695c 1.7px solid;
}

label {
  margin-bottom: 0.5px;
}
</style>
