import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;
const SecretKey = process.env.VUE_APP_SECRET_KEY;
const CryptoJS = require("crypto-js")

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        email: user.email,
        password: user.password,
        dashboard: true
      })
      .then(response => {
        // console.log('berhasil');
        const usr = JSON.stringify(response.data.data.user);
        const level = JSON.parse(usr)['jabatan_tim'];
        const new_user = {
          'name': JSON.parse(usr)['name'],
          'no_hp': JSON.parse(usr)['no_hp'],
          'lvl': JSON.parse(usr)['jabatan_tim'],
          'email': JSON.parse(usr)['email']
        }

        if (response.status == 200) {
          localStorage.setItem('user', JSON.stringify(new_user));
          localStorage.setItem('cll', CryptoJS.AES.encrypt(level.toString(), SecretKey).toString());
          localStorage.setItem('tkn', CryptoJS.AES.encrypt(response.data.data.access_token, SecretKey).toString());
          // localStorage.setItem('token', JSON.stringify(response.data.data.access_token));

          const localToken = CryptoJS.AES.decrypt(localStorage.getItem('tkn').toString(), SecretKey).toString(CryptoJS.enc.Utf8)
          // console.log('end token', localToken);
        }
        return response.data;
        this.$router.go('/');
      });
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();