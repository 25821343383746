<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item">
              <a href="/pages/pemain">Detail Pendaftaran</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Detail Pendaftaran Pemain
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link :to="'/pages/pemain-ubah?idp=' + id">
          <b-button
            variant="secondary"
            class="btn btn-add btn-sm mb-2 px-4 me-1"
          >
            <i class="fa fa-pencil mr-1"></i> &nbsp; Edit Pendaftaran
            Pemain</b-button
          >
        </router-link>
        <router-link to="/pages/pemain">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <!-- <div class="card-header pb-0">
            <h6>Ubah Data Pemain</h6>
          </div>
          <hr /> -->
          <div class="card-body mt-2">
            <h6 class="" style="color: #24695c">
              Data Pendaftaran Pemain (Player Data)
            </h6>
            <div class="text-center p-4">
              <img
                :src="urlImage + data_pemain.img"
                class="w-50 rounded border bg-success"
                style="height: 260px"
              />
            </div>
            <div class="text-center">
              <h4 class="text-uppercase">
                <b> {{ data_pemain.nama }} </b>
              </h4>
              <h6 class="text-uppercase" style="color: #24695c">
                <span v-if="data_pemain.posisi">
                  {{ data_pemain.posisi.nama_posisi }}</span
                >
                <span v-else>-</span>
              </h6>
            </div>
            <div class="mt-4">
              <div class="row">
                <hr />
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th width="45%">Tempat Lahir (TTL)</th>
                      <td>
                        {{ data_pemain.tempat_lahir }},
                        {{ data_pemain.tanggal_lahir }}
                      </td>
                    </tr>
                    <tr>
                      <th width="45%">Umur (Age)</th>
                      <td>
                        {{ data_pemain.umur }}
                      </td>
                    </tr>
                    <tr>
                      <th width="45%">Tinggi (Height)</th>
                      <td>{{ stats.height }} CM</td>
                    </tr>
                    <tr>
                      <th width="45%">Berat (Weight)</th>
                      <td>{{ stats.weight }} KG</td>
                    </tr>
                    <tr>
                      <th width="45%">Kaki Dominan (Dominant)</th>
                      <td>{{ stats.foot_dominant }}</td>
                    </tr>

                    <tr>
                      <th width="45%">Tim Skuad (Squad Team)</th>
                      <td>
                        <span
                          v-if="data_pemain.skuadteam"
                          style="color: #24695c; font-weight: 600"
                        >
                          {{ data_pemain.skuadteam.nama_skuad }}</span
                        >
                        <span v-else>-</span>
                      </td>
                    </tr>
                    <!-- <tr>
                      <th width="45%">Posisi (Position)</th>
                      <td>
                        <span v-if="data_pemain.posisi">
                          {{ data_pemain.posisi.nama_posisi }}</span
                        >
                        <span v-else>-</span>
                      </td>
                    </tr> -->
                    <tr>
                      <th width="45%">No Punggung (Number)</th>
                      <td>
                        {{ data_pemain.no_punggung }}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div class="mb-2 mt-3 pt-4 text-success">
              <h6 style="color: #24695c">Kepribadian (Personality)</h6>
            </div>
            <div class="row">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th width="45%">Disiplin (Discipline)</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          :style="'width:' + stats.k_discipline + '%'"
                          :aria-valuenow="stats.k_discipline"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ stats.k_discipline }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="45%">Motivasi (Motivation)</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          :aria-valuenow="stats.k_motivation"
                          :style="'width:' + stats.k_motivation + '%'"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ stats.k_motivation }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="45%">Mental (Mentality)</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          :aria-valuenow="stats.k_mental"
                          :style="'width:' + stats.k_mental + '%'"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ stats.k_mental }}
                          <!-- {{ data_pemain.stats.k_mental }} -->
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="45%">
                      Keputusan (Decision) {{ stats.k_decision }}
                    </th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          :aria-valuenow="stats.k_decision"
                          :style="'width:' + stats.k_decision + '%'"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ stats.k_decision }}
                          <!-- {{ data_pemain.stats.k_decision }} -->
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="45%">Kerjasama (Teamwork)</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          :aria-valuenow="stats.k_teamwork"
                          :style="'width:' + stats.k_teamwork + '%'"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ stats.k_teamwork }}
                          <!-- {{ data_pemain.stats.k_teamwork }} -->
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="45%">Menghormati (Respect)</th>
                    <td>
                      <div class="progress">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          :aria-valuenow="stats.k_respect"
                          :style="'width:' + stats.k_respect + '%'"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {{ stats.k_respect }}
                          <!-- {{ data_pemain.stats.respect }} -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PemainService from "@/_services/pemain.service";
import SkuadService from "@/_services/skuad.service";
import PosisiService from "@/_services/posisi.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.idp,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      data_pemain: [],
      stats: [],
      series: [
        {
          name: "nilai",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "radar",
        },
        title: {
          text: "Basic Skill",
        },
        xaxis: {
          categories: ["Passing", "Dribble", "Shoot", "Defance", "Attack"],
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#24695C"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#24695C",
          strokeWidth: 2,
        },
      },
    };
  },

  methods: {
    detailDetail() {
      // this.loading = true;
      PemainService.detailRegister(this.id)
        .then((response) => {
          // console.log("data", response.data.data);
          const val = response.data.data;
          this.data_pendaftaran_pemain = val;

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.detailDetail();
  },
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

th {
  color: #eeeeee;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
