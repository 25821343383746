<template>
  <div class="container-fluid w-100">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/pemain">Pemain</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Detail Pemain
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Detail Laporan Pemain
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <b-button
          variant="secondary"
          class="btn btn-success btn-sm mb-2 px-4 me-1"
          @click="generateReport()"
        >
          <i class="fa fa-download mr-1"></i> &nbsp; Download Laporan</b-button
        >
      </div>
    </div>

    <vue3-simple-html2pdf
      ref="vue3SimpleHtml2pdf"
      :options="pdfOptions"
      :filename="exportFilename"
    >
      <section class="mb-4 bg-white">
        <div class="mb-3" style="background-color: #f5f5f5">
          <div class="row" style="padding: 22px">
            <div class="col-sm-1 text-left">
              <img
                alt="avatar"
                :src="base_64_logo"
                class="w-100 mt-2"
                width="100%"
                height="50"
              />
            </div>
            <div class="col-sm-9">
              <h4 class="text-uppercase mb-1">
                <b>LAPORAN DATA PEMAIN </b>
              </h4>
              <span
                >Pelatih : <b> {{ data_pemain.skuadteam?.staf_pelatih }}</b
                >&nbsp;<br
              /></span>
              <span>Periode : <b> Juli 2024</b></span>
            </div>
            <div class="col-sm-2">
              <small>Score</small>
              <h1 class="text-success"><b>76</b></h1>
            </div>
          </div>
        </div>

        <div
          class="row mb-4"
          style="padding-left: 40px; padding-right: 44px  padding-bottom: 44px"
        >
          <div class="col-5">
            <div class="card-body mt-2 p-2">
              <h6 class="" style="color: #24695c">Data Pemain</h6>
              <div class="text-center p-2">
                <img
                  alt="avatar"
                  :src="base_64_avatar"
                  width="150"
                  height="150"
                />

                <div class="text-center">
                  <h5 class="text-uppercase mt-2">
                    <b> {{ data_pemain.nama }} </b>
                  </h5>
                  <span class="text-uppercase" style="color: #24695c">
                    <span v-if="data_pemain.posisi">
                      {{ data_pemain.posisi.nama_posisi }}</span
                    >
                    <span v-else>-</span>
                  </span>
                </div>
              </div>
              <div class="mt-2">
                <div class="row border-bottom border-top">
                  <div class="col-sm-5 p-2 py-2">
                    <div>
                      <b>Tempat Lahir</b>
                    </div>
                  </div>
                  <div class="col-sm-7 p-2 py-2">
                    {{ data_pemain.tempat_lahir }}
                  </div>
                </div>
                <div class="row border-bottom">
                  <div class="col-sm-5 p-2 py-2">
                    <div>
                      <b>Tanggal Lahir</b>
                    </div>
                  </div>
                  <div class="col-sm-7 p-2 py-2">
                    {{ data_pemain.tanggal_lahir }}
                  </div>
                </div>
                <div class="row border-bottom">
                  <div class="col-sm-5 p-2 py-2">
                    <div><b>Umur</b></div>
                  </div>
                  <div class="col-sm-7 p-2 py-2">
                    {{ data_pemain.umur }}
                  </div>
                </div>
                <div class="row border-bottom">
                  <div class="col-sm-5 p-2 py-2">
                    <div><b>Berat</b></div>
                  </div>
                  <div class="col-sm-7 p-2 py-2">{{ stats.weight }} KG</div>
                </div>

                <div class="row border-bottom">
                  <div class="col-sm-5 p-2 py-2">
                    <div><b>Kaki Dominan</b></div>
                  </div>
                  <div class="col-sm-7 p-2 py-2">
                    {{ stats.foot_dominant }}
                  </div>
                </div>
                <div class="row border-bottom">
                  <div class="col-sm-5 p-2 py-2">
                    <div><b>Tim Skuad</b></div>
                  </div>
                  <div class="col-sm-7 p-2 py-2">
                    <span
                      v-if="data_pemain.skuadteam"
                      style="color: #24695c; font-weight: 600"
                    >
                      {{ data_pemain.skuadteam.nama_skuad }}</span
                    >
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <div class="mb-2 mt-3 pt-4 text-success ml-0">
                <h6 style="color: #24695c">Fisik & Kepribadian</h6>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Kondisi</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.condition"
                      :style="'width:' + stats.condition + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.condition }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Daya Tahan</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.stamina"
                      :style="'width:' + stats.stamina + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.stamina }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Disiplin</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :style="'width:' + stats.k_discipline + '%'"
                      :aria-valuenow="stats.k_discipline"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.k_discipline }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Motivasi</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.k_motivation"
                      :style="'width:' + stats.k_motivation + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.k_motivation }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Mental</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.k_mental"
                      :style="'width:' + stats.k_mental + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.k_mental }}
                      <!-- {{ data_pemain.stats.k_mental }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Keputusan</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.k_decision"
                      :style="'width:' + stats.k_decision + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.k_decision }}
                      <!-- {{ data_pemain.stats.k_decision }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Kerjasama</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.k_teamwork"
                      :style="'width:' + stats.k_teamwork + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.k_teamwork }}
                      <!-- {{ data_pemain.stats.k_teamwork }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-5 p-1 py-2">
                  <div><b>Respect</b></div>
                </div>
                <div class="col-sm-7 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.k_respect"
                      :style="'width:' + stats.k_respect + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.k_respect }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mt-2 p-2 bg-succes text-left">
              <apexchart
                width="370"
                height="303"
                type="radar"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
            <div class="card-body" style="padding-left: 10px">
              <div class="text-success">
                <h6 style="color: #24695c">Keahlian</h6>
              </div>

              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Kecepatan Lari</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_speed"
                      :style="'width:' + stats.s_speed + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_speed }}
                      <!-- {{ data_pemain.stats.k_mental }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Kecepatan Gerakan</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_gk_reflex"
                      :style="'width:' + stats.s_gk_reflex + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_gk_reflex }}
                      <!-- {{ data_pemain.stats.k_mental }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Menggiring Bola</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_dribble"
                      :style="'width:' + stats.s_dribble + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_dribble }}
                      <!-- {{ data_pemain.stats.k_mental }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Akurasi Umpan</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_passing"
                      :style="'width:' + stats.s_passing + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_passing }}
                      <!-- {{ data_pemain.stats.s_passing }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Akurasi Tembakan</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_shoot"
                      :style="'width:' + stats.s_shoot + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_shoot }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Kekuatan Tembakan</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_shoot_power"
                      :style="'width:' + stats.s_shoot_power + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_shoot_power }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Duel Udara</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_area_duel"
                      :style="'width:' + stats.s_area_duel + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_area_duel }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Duel Fisik</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_physical_duel"
                      :style="'width:' + stats.s_physical_duel + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_physical_duel }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Sundulan</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_head"
                      :style="'width:' + stats.s_head + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_head }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Tekel</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_defence"
                      :style="'width:' + stats.s_defence + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_defence }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Mobilitas</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_mobility"
                      :style="'width:' + stats.s_mobility + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_mobility }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Bertahan</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_defence"
                      :style="'width:' + stats.s_defence + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_defence }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row border-bottom">
                <div class="col-sm-6 p-1 py-2">
                  <div><b>Menyerang</b></div>
                </div>
                <div class="col-sm-6 p-1 py-2">
                  <div class="progress" style="border-radius: 4px">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      :aria-valuenow="stats.s_attack"
                      :style="'width:' + stats.s_attack + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ stats.s_attack }}
                      <!-- {{ data_pemain.stats.respect }} -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- You can loop to display page number as you want -->
          <!-- <div class="html2pdf__page-number">1</div> -->

          <!-- Break page pdf -->
          <div class="html2pdf__page-break"></div>
          <div
            class="col-sm-11 mb-5 mt-3"
            style="padding-top: 40px margin-bottom: 40px"
          >
            <div class="card-body mt-2 border p-4">
              <div class="mb-2 text-success">
                <h6 style="color: #24695c">Catatan</h6>
              </div>
              <div class="row">
                <div class="col-sm-5">
                  <b>Turnamen di ikuti </b> <br />
                  {{ stats.tournament }}
                </div>
                <div class="col-sm-3">
                  <b>Main diturnamen</b> <br />
                  <h5 class="text-success">
                    {{ stats.s_main_tournament }}
                  </h5>
                </div>
                <div class="col-sm-3">
                  <b>Gol diturnamen </b> <br />
                  <h5 class="text-success">
                    {{ stats.s_goal_tournament }}
                  </h5>
                </div>
                <div class="col-sm-11 mt-4">
                  <b>Catatan Pelatih</b> <br />
                  {{ stats.note_coach }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <HelloWorldVue /> -->
        <!-- <div>
          <h4>Siapp</h4>
          <h5>okkk 1234</h5>
          <div>
            <img :src="base_64_avatar" width="200" height="200" />

            <div class="card-body mt-2">
              <div id="chart">
                <apexchart
                  type="radar"
                  height="392"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>  
        </div> -->
      </section>
    </vue3-simple-html2pdf>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PemainService from "@/_services/pemain.service";
import SkuadService from "@/_services/skuad.service";
import PosisiService from "@/_services/posisi.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";
import Vue3Html2pdf from "vue3-html2pdf";
import { ref } from "vue";
import HelloWorldVue from "./pdf.vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
    Vue3Html2pdf,
    HelloWorldVue,
  },
  data() {
    return {
      id: this.$route.query.idp,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      base_64_avatar: "",
      base_64_logo: "",
      show_report: false,
      data_pemain: [],
      stats: [],
      series: [
        {
          name: "nilai",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Basic Skill",
        },
        xaxis: {
          categories: [
            "Passing",
            "Dribble",
            "Shoot Accuracy",
            "Defance",
            "Attack",
          ],
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#24695C"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#24695C",
          strokeWidth: 2,
        },
      },
      enableDownload: "",
      previewModal: "",
      filename: "report",
      pdfOptions: {
        margin: 0,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 3 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exportFilename: "my-custom-file.pdf",
    };
  },

  methods: {
    detailDetail() {
      // this.loading = true;
      PemainService.detail(this.id)
        .then((response) => {
          const img_ava = this.urlImage + response.data.data.img;
          const val = response.data.data;
          const img_logo = this.urlImage + val.skuadteam?.images;
          this.data_pemain = val;
          this.stats = val.stats;
          console.log("data pemain", img_logo);
          this.convertBase64(img_ava);
          this.convertBase64Logo(img_logo);
          this.series[0].data.push(val.stats.s_passing);
          this.series[0].data.push(val.stats.s_shoot);
          this.series[0].data.push(val.stats.s_dribble);
          this.series[0].data.push(val.stats.s_defence);
          this.series[0].data.push(val.stats.s_attack);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    convertBase64(data) {
      console.log("mulai img");
      const img_replace = data.replace(/ /g, "%20");
      const data_url = {
        link: img_replace,
      };
      console.log("mulai img replace", data_url);
      // this.loading = true;
      PemainService.imgToBase(data_url)
        .then((response) => {
          console.log("data img", response.data.data);
          this.base_64_avatar = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    convertBase64Logo(data) {
      console.log("mulai img");
      const img_replace = data.replace(/ /g, "%20");
      const data_url = {
        link: img_replace,
      };
      console.log("mulai img replace", data_url);
      // this.loading = true;
      PemainService.imgToBase(data_url)
        .then((response) => {
          console.log("data img logo", response.data.data);
          this.base_64_logo = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    generateReport() {
      //  this.loading.generate = true;
      this.show_report = true;
      this.$refs.vue3SimpleHtml2pdf.download();
      // this.enableDownload = true;
      // this.previewModal = false;
      // this.filename = "report ";
      // setTimeout(() => {
      //   // this.loading.generate = false;
      //   this.$refs.html2Pdf.generatePdf();
      // }, 1000);
    },
  },
  mounted() {
    this.detailDetail();
  },
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}
.progress {
  border-radius: 4px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

th {
  color: #eeeeee;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
.main-content {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
</style>
