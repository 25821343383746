import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class JadwalService {
  get(params) {
    return axios.get(API_URL + 'jadwal-hasil?' + params, { headers: authHeader() });
  }
  add(data) {
    return axios.post(API_URL + 'jadwal-hasil', data, { headers: authHeader() });
  }
  detail(id) {
    return axios.get(API_URL + 'jadwal-hasil/' + id, { headers: authHeader() });
  }
  update(id, data) {
    return axios.post(API_URL + 'jadwal-hasil-update/' + id, data, { headers: authHeader() });
  }
  delete(id) {
    return axios.delete(API_URL + 'jadwal-hasil/' + id, { headers: authHeader() });
  }
}

export default new JadwalService();