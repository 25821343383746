import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// import vueChartist from "vue-chartist"
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap"
import "bootstrap/dist/js/bootstrap.min.js";
import 'bootstrap-icons/font/bootstrap-icons.css'
import Breadcrumbs from './layout/breadCrumbs.vue';
import VueApexCharts from "vue3-apexcharts";
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';
import VueNumber from "vue-number-animation";
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.css"
import './assets/scss/app.scss';
import VueFeather from "vue-feather";
import Lightbox from 'vue-easy-lightbox'
import Notifications from '@kyvg/vue3-notification'
// import 'form-wizard-vue3/dist/form-wizard-vue3.css';
// import Wizard from 'form-wizard-vue3';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue3Html2pdf from "vue3-html2pdf";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

// import SimpleTypeahead from 'vue3-simple-typeahead';
// import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
// import Toaster from "@meforma/vue-toaster";
// import { VueMasonryPlugin } from 'vue-masonry';
// import Vue3Tour from 'vue3-tour'
// import 'vue3-tour/dist/vue3-tour.css'
// import { quillEditor } from "vue3-quill";
// import rate from 'vue-rate'
// import 'vue-rate/dist/vue-rate.css'
// import VueKanban from 'vue-kanban'
// import Pxcard from "./layout/Pxcard"
// import PerfectScrollbar from 'vue3-perfect-scrollbar'
// import VueCountdown from '@chenfengyuan/vue-countdown';
// import firebase from 'firebase/app';
// import Vue3EasyDataTable from 'vue3-easy-data-table';
// import 'vue3-easy-data-table/dist/style.css';
// import BootstrapVue from 'bootstrap-vue';


import { configureFakeBackend } from './_helpers';
configureFakeBackend();

import config from './config.json';
// firebase.initializeApp(config.firebase);

// export const db = firebase.firestore();

createApp(App)
    // .use('EasyDataTable', Vue3EasyDataTable)
    .use(store)
    .use(router)
    .use(VueNumber)
    // .use(vueChartist)
    .use(BootstrapVue3)
    .use(Vue3SimpleHtml2pdf)
    // .use(require("vue-chartist"))
    .use(VueApexCharts)
    // .use(VueKanban)
    .component(VueFeather.name, VueFeather)
    // .component(VueCountdown.name, VueCountdown)
    .component('Breadcrumbs', Breadcrumbs)
    .component('Datepicker', Datepicker)
    // .component(Pxcard.name, Pxcard)
    .use(VCalendar, {})
    .use(Lightbox)
    // .use(PerfectScrollbar)
    .use(VueSweetalert2)
    // .use(Wizard)
    .use(Notifications)
    .use(Vue3Html2pdf)
    .component('multiselect', Multiselect)
    // .use(require("vue-chartist"))
    // .use(rate)
    // .use(SimpleTypeahead)
    // .use(Toaster)
    // .use(quillEditor)
    // .use(Vue3Tour)
    // .use(VueMasonryPlugin)
    // .use(BootstrapVue)

    .mount('#app')