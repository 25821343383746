import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class UserAdminService {
  getUser() {
    return axios.get(API_URL + 'user-all', { headers: authHeader() });
  }

  detail(id) {
    return axios.get(API_URL + 'user-detail/'+id, { headers: authHeader() });
  }

  add(data) {
    return axios.post(API_URL + 'user-add', data, { headers: authHeader() });
  }

  update(id, data) {
    return axios.put(API_URL + 'user-update/' + id,  data, { headers: authHeader() });
  }

  resetPassword(data) {
    return axios.post(API_URL + 'user-reset-password', data, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + 'user-delete/' + id, { headers: authHeader() });
  }
}

export default new UserAdminService();