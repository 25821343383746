<template>
  <i data-feather="message-square">
    <img
      class="img-fluid rounded-circle me-3"
      src="../../assets/images/user/user.png"
      alt=""
      width="30"
    />
    <span v-if="user">{{ JSON.parse(user)["name"] }}</span>
  </i>
  <ul class="chat-dropdown onhover-show-div">
    <li>
      <div class="media">
        <img
          class="img-fluid rounded-circle me-3"
          src="../../assets/images/user/user.png"
          alt=""
        />
        <div class="media-body" v-if="user">
          <span
            ><b>{{ JSON.parse(user)["email"] }}</b></span
          >
          <p class="f-12 light-font">
            {{ JSON.parse(user)["lvl"] }}
          </p>
        </div>
      </div>
    </li>
    <li class="text-center">
      <span @click="logout" class="btn btn-block text-danger">Logout</span>
    </li>
  </ul>
</template>
<script>
import router from "@/router";

export default {
  components: {},
  data() {
    return {
      user: localStorage.getItem("user"),
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("tkn");
      localStorage.removeItem("cll");
      router.push("/auth/login");
    },
  },
};
</script>
