import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class AgendaService {
  get(params) {
    return axios.get(API_URL + 'agenda-tim?' + params, { headers: authHeader() });
  }

  add(data) {
    return axios.post(API_URL + 'agenda-tim', data, { headers: authHeader() });
  }

  update(id, data) {
    return axios.post(API_URL + 'agenda-tim-update/' + id, data, { headers: authHeader() });
  }

  detail(id) {
    return axios.get(API_URL + 'agenda-tim/' + id, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + 'agenda-tim/' + id, { headers: authHeader() });
  }

  addFotoGaleri(data) {
    return axios.post(API_URL + 'galeri-foto', data, { headers: authHeader() });
  }
  deleteGaleri(id) {
    return axios.delete(API_URL + 'galeri-foto/' + id, { headers: authHeader() });
  }
}

export default new AgendaService();