<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-b="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/agenda">Agenda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Detail Agenda
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/agenda">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div><b>Judul : </b> {{ judul }}</div>
                <div><b>Tipe : </b> {{ tipe }}</div>
                <div><b>Jumlah Foto : </b> {{ gallery1.length }}</div>
                <div>
                  <b class="mtb-0">Foto Sampul</b>
                  <div class="mt-0">
                    <img :src="previewUrl" class="rounded mr-0 mt-1 w-25" />
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <form class="row g-3 needs-validation" novalidate="">
                  <label for="">Tambah Foto Galeri</label>
                  <div class="col-md-4 position-relative">
                    <b for="formFileSm" class=""
                      >Foto Baru
                      <span class="text-danger">*Maksimal 4Mb</span></b
                    >
                    <input
                      class="form-control form-control-sm"
                      id="formFileSm"
                      type="file"
                      @change="imageagenda"
                      accept="image/*"
                      v-bind:class="
                        formSubmitted
                          ? image_agendaError
                            ? 'is-invalid'
                            : 'is-valid'
                          : ''
                      "
                    />
                    <small
                      class="text-danger"
                      v-if="image_agendaError && formSubmitted"
                    >
                      Foto harus di isi !
                    </small>
                  </div>
                  <div class="col-12 text-start">
                    <button
                      class="btn btn-primary"
                      @click="simpanAgenda"
                      type="button"
                    >
                      <span v-if="loading">
                        <div
                          class="spinner-border spinner-border-sm"
                          role="tempat_lahir"
                        >
                          <span class="sr-only"></span>
                        </div>
                        Loading ..
                      </span>
                      <span v-else> Simpan </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0">
            <h5>Galeri Foto</h5>
          </div>
          <div class="card-body">
            <div
              class="row my-gallery gallery"
              id="aniimated-thumbnials"
              itemscope=""
              data-pswp-uid="1"
            >
              <figure
                class="col-sm-3 col-6 img-hover hover-1 o-hidden"
                v-for="(n, index) in gallery1"
                :key="index"
                itemprop="associatedMedia"
                itemscope=""
              >
                <div>
                  <img
                    :src="urlImage + n.url_foto"
                    itemprop="thumbnail"
                    alt="Image description"
                    class="img-fluid"
                    style="height: 250px"
                  />
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-sm btn-light w-full text-center mt-2"
                    @click="deleteFoto(n.id)"
                  >
                    Hapus
                  </button>
                </div>
              </figure>
            </div>
            <vue-easy-lightbox
              :index="index"
              :visible="visible"
              :imgs="lightBoxImages"
              @hide="handleHide"
            >
            </vue-easy-lightbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AgendaService from "@/_services/agenda.service";
import Swal from "sweetalert2";
import DropZone from "dropzone-vue";

export default {
  components: {
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.ida,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      image_agenda: "",
      previewUrl: null,
      image_agendaError: false,
      id_agenda: "",
      errors: [],
      formSubmitted: false,
      judul: "",
      tipe: "",
      validation: false,
      lightBoxImages: [],
      visible: false,
      gallery1: [],
    };
  },
  mounted() {
    this.detailDetail();
    // this.gallery1.forEach((item) => {
    //   this.lightBoxImages.push(
    //     "https://api.ssbputramanggala.com//storage/images/galleryfoto/555-photo_2023-12-15_16-24-16.jpg"
    //   );
    // });
  },

  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    detailDetail() {
      // this.loading = true;
      AgendaService.detail(this.id)
        .then((response) => {
          console.log("data", response.data.data);
          const val = response.data.data;
          this.id_agenda = val.id;
          this.previewUrl = this.urlImage + val.foto;
          this.judul = val.judul;
          this.tipe = val.type;
          this.gallery1 = val.galeri;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    imageagenda(evt) {
      this.image_agenda = evt.target.files[0];
      // this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.image_agenda) {
        this.image_agendaError = false;
      } else {
        this.image_agendaError = "Image harus di isi !";
      }
    },

    simpanAgenda() {
      this.formSubmitted = true;
      this.validationForm();

      if (this.image_agenda) {
        this.validation = true;
        this.loading = true;
        const formData = new FormData();
        formData.append("id_agenda", this.id_agenda);
        formData.append("image", this.image_agenda);
        AgendaService.addFotoGaleri(formData)
          .then((response) => {
            // console.log("berhasil", response.data);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Foto Berhasil Diupload",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading = false;
            // this.$router.push("/pages/agenda-detail?ida=" + this.id_agenda);
            this.resetForm();
            this.detailDetail();
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Foto Gagal Disimpan!",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    deleteFoto(id) {
      // console.log("delete", id);
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus foto ini?",
        text: "-",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#153F37",
        cancelButtonColor: "#eeeeee",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("id", id);
          AgendaService.deleteGaleri(id)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500,
              });
              this.detailDetail();
            })
            .catch((err) => {
              console.log("err ", err);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Gagal",
                text: "Hapus Foto Gagal",
                showConfirmButton: false,
                timer: 2500,
              });
            });
        }
      });
    },

    resetForm() {
      console.log("rseset ");
      this.loading = false;
      this.image_agenda = "";
      this.previewUrl = null;
      this.image_agendaError = false;
      this.errors = [];
      this.formSubmitted = false;
      this.judul = "";
      this.judulError = false;
      this.type = "";
      this.validation = false;
    },
  },
};
</script>

<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}

.form-control {
  border: #a8a7a7 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.7px solid;
}
</style>
