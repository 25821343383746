<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item">
              <a href="/pages/ofisial">Ofisial</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Tambah Ofisial Baru
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/ofisial">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Data Ofisial</h6>
          </div>
          <hr />
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-4 position-relative">
                <label class="mb-1" for="validationTooltip02"
                  >Nama Ofisial</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.namaError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="nama"
                  placeholder="Nama"
                  v-model="form.nama"
                />
                <small
                  class="align-end text-danger"
                  v-if="formErorr.namaError && formSubmitted"
                >
                  {{ formErorr.namaError }}
                </small>
              </div>

              <div class="col-md-3 position-relative">
                <label class="mb-1" for="validationTooltip02"
                  >Tempat Lahir</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.tempat_lahirError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="tempat_lahir"
                  placeholder="Tempat lahir"
                  v-model="form.tempat_lahir"
                />
                <small
                  class="align-end text-danger"
                  v-if="formErorr.tempat_lahirError && formSubmitted"
                >
                  {{ formErorr.tempat_lahirError }}
                </small>
              </div>

              <div class="col-md-2 position-relative">
                <label class="mb-1" for="validationTooltip01"
                  >Tanggal Lahir</label
                >
                <input
                  type="date"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.tanggal_lahirError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="tanggal_lahir"
                  placeholder="tanggal_lahir"
                  v-model="form.tanggal_lahir"
                />
                <small
                  class="align-end text-danger"
                  v-if="formErorr.tanggal_lahirError && formSubmitted"
                >
                  {{ formErorr.tanggal_lahirError }}
                </small>
              </div>

              <div class="col-md-3 position-relative">
                <label class="mb-1" for="validationTooltip04">Posisi </label>
                <select
                  type="text"
                  class="form-control"
                  id="posisi"
                  v-model="form.posisi_id"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? formErorr.posisiError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">- Pilih Posisi -</option>
                  <option
                    :value="list.id"
                    v-for="list in posisi_list"
                    :key="list.id"
                  >
                    {{ list.nama_posisi }}
                  </option>
                </select>
                <small
                  class="text-danger"
                  v-if="formErorr.posisiError && formSubmitted"
                >
                  {{ formErorr.posisiError }}
                </small>
              </div>

              <div class="col-md-2 position-relative">
                <label class="mb-1" for="validationTooltip01"
                  >Tanggal Bergabung</label
                >
                <input
                  type="date"
                  class="form-control"
                  id="tgl_bergabung"
                  placeholder="tgl_bergabung"
                  v-model="form.tgl_bergabung"
                />
              </div>

              <div class="col-md-10 position-relative">
                <label class="mb-1" for="validationTooltip11">Lisensi</label>
                <input
                  type="text"
                  class="form-control"
                  id="lisensi"
                  placeholder="Lisensi"
                  v-model="form.lisensi"
                />
              </div>

              <div class="col-md-12 position-relative">
                <label class="mb-1" for="validationTooltip11">Keterangan</label>
                <input
                  type="text"
                  class="form-control"
                  id="keterangan"
                  placeholder="Keterangan"
                  v-model="form.keterangan"
                />
              </div>

              <div class="col-md-4 position-relative">
                <b for="formFileSm" class="">Foto ofisial</b>
                <input
                  class="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  @change="imageShow"
                  accept="image/*"
                  v-bind:class="
                    formSubmitted ? (!images ? 'is-invalid' : 'is-valid') : ''
                  "
                />
                <small class="text-danger" v-if="!images && formSubmitted">
                  images harus di isi !
                </small>
                <div>
                  <img
                    :src="previewUrl"
                    class="rounded mr-4 mt-4 w-20"
                    width="100"
                  />
                </div>
              </div>
              <div class="col-12 text-end">
                <button class="btn btn-light" @click="resetForm" type="button">
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanofisial"
                  type="button"
                >
                  <span v-if="loading">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="tempat_lahir"
                    >
                      <span class="sr-only"></span>
                    </div>
                    Loading ..
                  </span>
                  <span v-else> Simpan </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ofisialService from "@/_services/ofisial.service";
import SkuadService from "@/_services/skuad.service";
import PosisiService from "@/_services/posisi.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      skuad_list: [],
      posisi_list: [],
      form: {
        tgl_bergabung: "",
        nama: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        posisi_id: "",
        lisensi: "",
        statistik: "",
        keterangan: "",
      },
      formErorr: {
        namaError: false,
        posisiError: false,
        tempat_lahirError: false,
        tanggal_lahirError: false,
      },
      images: "",
      previewUrl: null,
      imagesError: false,
      validation: false,
      errors: [],
      formSubmitted: false,
      editor: ClassicEditor,
    };
  },

  methods: {
    imageShow(evt) {
      this.images = evt.target.files[0];
      this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.form.nama.length < 1) {
        this.formErorr.namaError = "nama harus di isi !";
      } else {
        this.formErorr.namaError = false;
      }

      if (this.form.tanggal_lahir.length < 1) {
        this.formErorr.tanggal_lahirError = "tanggal lahir harus di isi !";
      } else {
        this.formErorr.tanggal_lahirError = false;
      }

      if (this.form.tempat_lahir.length < 1) {
        this.formErorr.tempat_lahirError = "tempat lahir harus di isi !";
      } else {
        this.formErorr.tempat_lahirError = false;
      }

      if (this.form.posisi_id.length < 1) {
        this.formErorr.posisiError = "jabatan harus di isi !";
      } else {
        this.formErorr.posisiError = false;
      }

      if (this.images.length < 1) {
        this.imagesError = true;
        this.errors.push({
          message: "foto harus di isi !",
        });
      } else {
        this.imagesError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanofisial() {
      this.formSubmitted = true;
      this.validationForm();

      if (
        this.form.tanggal_lahir &&
        this.form.tgl_bergabung &&
        this.images &&
        this.form.posisi_id &&
        this.form.nama
      ) {
        this.loading = true;
        this.validation = true;

        const formData = new FormData();
        formData.append("tgl_bergabung", this.form.tgl_bergabung);
        formData.append("nama", this.form.nama);
        formData.append("tempat_lahir", this.form.tempat_lahir);
        formData.append("tanggal_lahir", this.form.tanggal_lahir);
        formData.append("lisensi", this.form.lisensi);
        formData.append("posisi_id", this.form.posisi_id);
        formData.append("keterangan", this.form.keterangan);
        formData.append("img", this.images);

        console.log("fd", FormData);
        ofisialService
          .add(formData)
          .then((response) => {
            console.log("berhasil", response.data);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Data Berhasil Ditambah",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading = false;
            this.$router.push("/pages/ofisial");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Data Gagal Ditambah",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        console.log("validation failed");
      }
    },

    listSkuad() {
      SkuadService.dropdown()
        .then((response) => {
          this.skuad_list = [];
          let data_tim = response.data.data;
          console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              id: val.id,
              nama_skuad: val.nama_skuad,
            };
            this.skuad_list.push(data);
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    listPosisi() {
      PosisiService.dropdownOfisial()
        .then((response) => {
          this.posisi_list = [];
          let list_data = response.data.data;
          console.log("data", response.data.data);
          list_data.forEach((val, index) => {
            const data = {
              id: val.id,
              nama_posisi: val.nama_posisi,
            };
            this.posisi_list.push(data);
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    resetForm() {
      console.log("rseset ");
      this.loading = false;
      this.images = "";
      this.previewUrl = null;
      this.imagesError = false;
      this.errors = [];
      this.formSubmitted = false;
      this.validation = false;
      this.form = {
        tgl_bergabung: "",
        nama: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        posisi_id: "",
        lisensi: "",
        keterangan: "",
      };
      this.formErorr = {
        namaError: false,
        tgl_bergabungError: false,
        posisiError: false,
        tempat_lahirError: false,
        tanggal_lahirError: false,
        tempat_lahirError: false,
      };
    },
  },
  mounted() {
    this.listSkuad();
    this.listPosisi();
  },
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
