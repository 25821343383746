// export function authHeader() {

//     let user = JSON.parse(localStorage.getItem('user'));

//     if (user && user.token) {
//         return { 'Authorization': 'Bearer ' + user.token };
//     } else {
//         return {};
//     }
// }

export default function authHeader() {
  const SecretKey = process.env.VUE_APP_SECRET_KEY;
  const CryptoJS = require("crypto-js")
  // let token = JSON.parse(localStorage.getItem('token'));
  let localToken = CryptoJS.AES.decrypt(localStorage.getItem('tkn').toString(), SecretKey).toString(CryptoJS.enc.Utf8) ?? null

  if (localToken) {
    return { Authorization: 'Bearer ' + localToken }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}