<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              Daftar Susunan Pemain
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <b-button
          variant="secondary"
          class="btn btn-success btn-sm mb-2 px-4 me-1"
          @click="generateReport()"
        >
          <i class="fa fa-download mr-1"></i> &nbsp; Download DSP</b-button
        >
      </div>
    </div>

    <vue3-simple-html2pdf
      ref="vue3SimpleHtml2pdf"
      :options="pdfOptions"
      :filename="exportFilename"
    >
      <section class="bg-white">
        <div class="mb-3">
          <div class="row" style="padding: 22px">
            <div class="col-sm-2 text-left">
              <img
                alt="avatar"
                :src="base_64_logo"
                class="w-100 mt-2"
                width="100%"
                height="60"
              />
            </div>
            <div class="col-sm-8 text-center">
              <h4 class="text-uppercase mb-1">
                <b>DAFTAR SUSUNAN PEMAIN (DSP) </b> <br />
                LAGA {{ detail_data.tipe_pertandingan }} 2024
              </h4>
            </div>
            <div class="col-sm-2">
              <img
                alt="avatar"
                :src="base_64_logo_lawan"
                class="w-100 mt-2"
                width="100%"
                height="60"
              />
            </div>
          </div>

          <div class="row" style="padding: 22px">
            <div class="col-sm-12">
              <table class="w-100">
                <tr>
                  <th>NP</th>
                  <th>Tanggal</th>
                  <th>Kickoff</th>
                  <th colspan="5">Stadion</th>
                  <th>Durasi</th>
                  <th>Cuaca</th>
                  <th>Penonton</th>
                </tr>
                <tr>
                  <td>#001</td>
                  <td>{{ detail_data.tanggal }}</td>
                  <td>{{ detail_data.jam }}</td>
                  <td colspan="5">{{ detail_data.tempat }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="3" rowspan="4">
                    <b>{{ detail_data.skuad?.nama_skuad }}</b>
                  </td>
                  <td rowspan="4"><b>&nbsp;</b></td>
                  <td>&nbsp;</td>
                  <td width="20%">Babak 1</td>
                  <td>&nbsp;</td>
                  <td rowspan="4"><b>&nbsp; </b></td>
                  <td colspan="3" rowspan="4">
                    <b>{{ detail_data.tim_lawan }}</b>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td width="20%">Babak 2</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>Extra Time</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>Penalty</td>
                  <td></td>
                </tr>
                <tr>
                  <th colspan="3">&nbsp;</th>
                  <th colspan="5"></th>
                  <th colspan="3">&nbsp;</th>
                </tr>
              </table>
            </div>

            <div class="col-sm-6 mt-4">
              <table class="w-100">
                <tr>
                  <th width="7%">NPG</th>
                  <th>Nama Pemain</th>
                  <th width="8%">G</th>
                  <th width="8%">Y</th>
                  <th width="8%">R</th>
                  <th width="8%">S</th>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>

                <tr rowspan="6" style="background-color: #e7e7e8">
                  <th colspan="6">Pemain Cadangan</th>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
              </table>

              <table class="mt-3 w-100">
                <tr style="background-color: #e7e7e8">
                  <th>Pelatih Kepala</th>
                  <th>Manager Tim</th>
                </tr>
                <tr>
                  <th>&nbsp;</th>
                  <th></th>
                </tr>
              </table>
            </div>

            <div class="col-sm-6 mt-4">
              <table class="w-100">
                <tr>
                  <th width="7%">NPG</th>
                  <th>Nama Pemain</th>
                  <th width="8%">G</th>
                  <th width="8%">Y</th>
                  <th width="8%">R</th>
                  <th width="8%">S</th>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>

                <tr rowspan="6" style="background-color: #e7e7e8">
                  <th colspan="6">Pemain Cadangan</th>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
                <tr>
                  <td width="7%">&nbsp;</td>
                  <td></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                  <td width="8%"></td>
                </tr>
              </table>

              <table class="mt-3 w-100">
                <tr style="background-color: #e7e7e8">
                  <th>Pelatih Kepala</th>
                  <th>Manager Tim</th>
                </tr>
                <tr>
                  <th>&nbsp;</th>
                  <th></th>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-12 px-4 pb-2">
              <b>*</b> [ <b>G : </b> Gol ] - [ <b> Y : </b> Kartu Kuning ] - [
              <b> R : </b> Kartu Merah ] - [ <b> S : </b> Pergantian ]
            </div>
          </div>
        </div>
      </section>
    </vue3-simple-html2pdf>
  </div>
</template>
<script>
import { mapState } from "vuex";
import JadwalService from "@/_services/jadwal.service";
import PemainService from "@/_services/pemain.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";
import Vue3Html2pdf from "vue3-html2pdf";
import { ref } from "vue";

export default {
  props: {
    // idd: String,
  },
  components: {
    ckeditor: CKEditor.component,
    DropZone,
    Vue3Html2pdf,
  },
  data() {
    return {
      idd: this.$route.query.idj,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      base_64_logo_lawan: "",
      base_64_logo: "",
      show_report: false,
      detail_data: [],
      stats: [],
      series: [
        {
          name: "nilai",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Basic Skill",
        },
        xaxis: {
          categories: [
            "Passing",
            "Dribble",
            "Shoot Accuracy",
            "Defance",
            "Attack",
          ],
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#24695C"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#24695C",
          strokeWidth: 2,
        },
      },
      enableDownload: "",
      previewModal: "",
      filename: "report",
      pdfOptions: {
        margin: 0,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 3 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exportFilename: "my-custom-file.pdf",
    };
  },

  methods: {
    detailDetail() {
      this.loading = true;
      JadwalService.detail(this.idd)
        .then((response) => {
          console.log("data deatail", response.data.data);
          const val = response.data.data;
          this.detail_data = val;

          const img_logo = this.urlImage + val.logo_lawan;
          const img_logo_lawan = this.urlImage + val.skuad?.images;
          // console.log("data pemain", img_logo);
          this.convertBase64(img_logo);
          this.convertBase64Logo(img_logo_lawan);

          // this.previewUrl = this.urlImage + val.images;
          // this.nama_skuad = val.nama_skuad;
          // this.staf_pelatih = val.staf_pelatih;
          // this.list_pemain = val.pemain;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    convertBase64(data) {
      // console.log("mulai img");
      const img_replace = data.replace(/ /g, "%20");
      const data_url = {
        link: img_replace,
      };
      // console.log("mulai img replace", data_url);
      // this.loading = true;
      PemainService.imgToBase(data_url)
        .then((response) => {
          // console.log("data img", response.data.data);
          this.base_64_logo = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    convertBase64Logo(data) {
      // console.log("mulai img");
      const img_replace = data.replace(/ /g, "%20");
      const data_url = {
        link: img_replace,
      };
      // console.log("mulai img replace", data_url);
      // this.loading = true;
      PemainService.imgToBase(data_url)
        .then((response) => {
          // console.log("data img logo", response.data.data);
          this.base_64_logo_lawan = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    generateReport() {
      //  this.loading.generate = true;
      this.show_report = true;
      this.$refs.vue3SimpleHtml2pdf.download();
      // this.enableDownload = true;
      // this.previewModal = false;
      // this.filename = "report ";
      // setTimeout(() => {
      //   // this.loading.generate = false;
      //   this.$refs.html2Pdf.generatePdf();
      // }, 1000);
    },
  },
  mounted() {
    this.detailDetail();
  },
};
</script>

<style lang="scss" scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

table {
  font-size: 14px;
  text-align: center;
  border: 1px solid #2c313b;
  border-collapse: collapse;
  th {
    font-weight: bold;
    color: #242934;
  }
  td,
  th {
    padding: 3px;
    border: 1px solid #2c313b;
  }
}

.progress {
  border-radius: 4px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

th {
  color: #eeeeee;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
.main-content {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
</style>
