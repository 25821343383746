<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Profile Tim
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/berita">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0"></div>
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-12"><b>Profil Tim</b></div>
              <div class="col-sm-4">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <label>Logo Tim</label>
                      </div>
                      <div class="col-sm-4 mb-2 text-center">
                        <img
                          :src="urlImage + form.img"
                          class="rounded w-100 ml-2"
                          v-if="!previewUrl"
                        />
                        <img
                          :src="previewUrl"
                          class="rounded mt-1 w-100 ml-2"
                          style="margin-left: 20px"
                          v-if="previewUrl"
                        />
                      </div>
                      <div class="col-sm-8 text-left pl-2">
                        <div class="text-danger">
                          *Ukuran file maksimal adalah 2Mb
                        </div>
                        <div class="text-danger">
                          *Format logo disarankan .png
                        </div>
                      </div>
                    </div>

                    <div class="mt-2 text-center">
                      <input
                        class="form-control form-control-sm"
                        id="formFileSm"
                        type="file"
                        @change="imageberita"
                        accept="image/*"
                        ref="fileimg"
                      />
                      <div class="text-center">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mb-3">
                        <label class="form-label" for="validationTooltip01"
                          >Nama Tim</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          @change="validationForm"
                          v-bind:class="
                            formSubmitted
                              ? nama_timError
                                ? 'is-invalid'
                                : 'is-valid'
                              : ''
                          "
                          id="nama_tim"
                          placeholder="Nama Tim"
                          v-model="form.nama_tim"
                        />
                        <small
                          class="align-end text-danger"
                          v-if="nama_timError && formSubmitted"
                        >
                          {{ nama_timError }}
                        </small>
                      </div>
                      <div class="col-md-12 mb-3">
                        <label class="form-label" for="validationTooltip02"
                          >Color Code</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          @change="validationForm"
                          v-bind:class="
                            formSubmitted
                              ? color_codeError
                                ? 'is-invalid'
                                : 'is-valid'
                              : ''
                          "
                          id="color"
                          placeholder="#"
                          v-model="form.color_code"
                        />
                        <small
                          class="text-danger"
                          v-if="errors[1] && formSubmitted"
                        >
                          {{ errors[1].message }}
                        </small>
                      </div>
                      <div class="col-md-12 mb-2">
                        <label class="form-label" for="validationTooltip02"
                          >Version Apps</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          @change="validationForm"
                          v-bind:class="
                            formSubmitted
                              ? versionError
                                ? 'is-invalid'
                                : 'is-valid'
                              : ''
                          "
                          id="color"
                          placeholder="#"
                          v-model="form.version"
                        />
                        <small
                          class="text-danger"
                          v-if="errors[1] && formSubmitted"
                        >
                          {{ errors[1].message }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 border mx-2 pr-5">
                <div class="row p-3">
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="validationTooltip03"
                      >Visi</label
                    >
                    <ckeditor :editor="editor" v-model="form.visi"> </ckeditor>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="validationTooltip03"
                      >Misi</label
                    >
                    <ckeditor :editor="editor" v-model="form.misi"> </ckeditor>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="validationTooltip03"
                      >Info 1</label
                    >
                    <textarea
                      v-model="form.info_1"
                      class="form-control"
                      rows="3"
                      placeholder="info 1"
                    ></textarea>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="validationTooltip03"
                      >Info 2</label
                    >
                    <textarea
                      v-model="form.info_2"
                      class="form-control"
                      rows="3"
                      placeholder="info 1"
                    ></textarea>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label class="form-label" for="validationTooltip03"
                      >Deskripsi</label
                    >
                    <ckeditor :editor="editor" v-model="form.deskripsi">
                    </ckeditor>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end">
                <button
                  class="btn btn-light"
                  @click="detailDetail"
                  type="button"
                >
                  Reset
                </button>
                &nbsp;
                <button class="btn btn-primary" @click="saveData" type="button">
                  <span v-if="loading">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="tempat_lahir"
                    >
                      <span class="sr-only"></span>
                    </div>
                    Loading ..
                  </span>
                  <span v-else> Simpan </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import SettingService from "@/_services/setting.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.idb,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      form: {
        id: "",
        version: "",
        img: "",
        nama_tim: "",
        color_code: "",
        info_1: "",
        info_2: "",
        visi: "",
        misi: "",
        deskripsi: "",
        created_at: "",
        updated_at: "",
      },
      is_edit: false,
      loading: false,
      logo_tim: "",
      previewUrl: null,
      logo_timError: false,
      errors: [],
      formSubmitted: false,
      nama_tim: "",
      nama_timError: "",
      sub_nama_tim: "",
      color_codeError: false,
      misi: "",
      misiError: false,
      info_1: "",
      visi: "",
      visiError: false,
      validation: false,

      editor: ClassicEditor,
    };
  },
  mounted() {
    this.detailDetail();
  },

  methods: {
    detailDetail() {
      // this.loading = true;
      SettingService.get(this.id)
        .then((response) => {
          console.log("data", response.data.data);
          const val = response.data.data;
          this.form = {
            id: val.id,
            version: val.version,
            img: val.img,
            nama_tim: val.nama_tim,
            color_code: val.color_code,
            info_1: val.info_1,
            info_2: val.info_2,
            visi: val.visi,
            misi: val.misi,
            deskripsi: val.deskripsi,
            created_at: val.created_at,
            updated_at: val.updated_at,
          };
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    imageberita(evt) {
      this.logo_tim = evt.target.files[0];
      this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.form.nama_tim.length < 1) {
        this.nama_timError = "nama tim harus di isi !";
      } else {
        this.nama_timError = "";
      }
    },

    saveData() {
      this.formSubmitted = true;
      this.validationForm();

      if (this.form.nama_tim && this.form.version) {
        this.validation = true;
        this.loading = true;

        if (this.logo_tim) {
          console.log("with logo");
          const formData = new FormData();
          formData.append("nama_tim", this.form.nama_tim);
          formData.append("color_code", this.form.color_code);
          formData.append("visi", this.form.visi);
          formData.append("img", this.logo_tim);
          formData.append("misi", this.form.misi);
          formData.append("info_1", this.form.info_1);
          formData.append("info_2", this.form.info_2);
          formData.append("version", this.form.version);
          formData.append("deskripsi", this.form.deskripsi);

          SettingService.update(this.form.id, formData)
            .then((response) => {
              console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              this.$router.push("/pages/setting");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          const formData = new FormData();
          formData.append("nama_tim", this.form.nama_tim);
          formData.append("color_code", this.form.color_code);
          formData.append("visi", this.form.visi);
          formData.append("misi", this.form.misi);
          formData.append("info_1", this.form.info_1);
          formData.append("info_2", this.form.info_2);
          formData.append("version", this.form.version);
          formData.append("deskripsi", this.form.deskripsi);

          SettingService.update(this.form.id, formData)
            .then((response) => {
              console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              this.$router.push("/pages/setting");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    },

    resetForm() {
      console.log("rseset ");
      this.$refs.fileimg.value = null;
      this.detailDetail();
      // this.loading = false;
      // this.logo_tim = "";
      // this.previewUrl = null;
      // this.logo_timError = false;
      // this.errors = [];
      // this.formSubmitted = false;
      // this.nama_tim = "";
      // this.nama_timError = false;
      // this.sub_nama_tim = "";
      // this.color_codeError = false;
      // this.misi = "";
      // this.misiError = false;
      // this.info_1 = "";
      // this.visi = "";
      // this.visiError = false;
      // this.validation = false;
    },
  },
};
</script>

<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.form-control {
  border: #a8a7a7 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.7px solid;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
