import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class PemainService {
  get(params) {
    return axios.get(API_URL + 'pemain?' + params, { headers: authHeader() });
  }

  dropdown() {
    return axios.get(API_URL + 'pemain-list-all', { headers: authHeader() });
  }

  add(data) {
    return axios.post(API_URL + 'pemain', data, { headers: authHeader() });
  }

  imgToBase(data) {
    return axios.post(API_URL + 'pemain-img-base', data, { headers: authHeader() });
  }

  updatePemainSkuad(data) {
    return axios.post(API_URL + 'pemain-add-skuad', data, { headers: authHeader() });
  }

  update(id, data) {
    return axios.post(API_URL + 'pemain-update/' + id, data, { headers: authHeader() });
  }

  updateStats(data) {
    return axios.post(API_URL + 'pemain-stats', data, { headers: authHeader() });
  }

  detail(id) {
    return axios.get(API_URL + 'pemain/' + id, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + 'pemain/' + id, { headers: authHeader() });
  }


  // pendaftaran
  getRegister(params) {
    return axios.get(API_URL + 'pemain-register?' + params, { headers: authHeader() });
  }

  updateRegister(id, data) {
    return axios.post(API_URL + 'pemain-register-update/' + id, data, { headers: authHeader() });
  }

  updateStatus(id, data) {
    return axios.post(API_URL + 'pemain-register-action/' + id, data, { headers: authHeader() });
  }

  detailRegister(id) {
    return axios.get(API_URL + 'pemain-register/' + id, { headers: authHeader() });
  }

  deleteRegister(id) {
    return axios.delete(API_URL + 'pemain-register/' + id, { headers: authHeader() });
  }
}

export default new PemainService();