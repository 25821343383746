<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/jadwal">Jadwal</a></li>

            <li class="breadcrumb-item active" aria-current="page">
              Detail Jadwal Pertandingan
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/jadwal">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
        <b-button variant="primary" class="btn btn-add btn-sm mb-2 px-4 ms-2">
          <i class="fa fa-file-pdf-o mr-1"></i> &nbsp; Cetak</b-button
        >
        <router-link :to="'/pages/jadwal-detail-dsp?idj=' + id" target="_blank">
          <b-button variant="success" class="btn btn-add btn-sm mb-2 px-4 ms-2">
            <i class="fa fa-file-pdf-o mr-1"></i> &nbsp; Match Summary (DSP)
          </b-button>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div>
                <div class="row invo-header">
                  <div class="col-sm-6">
                    <div>
                      <h5 class="media-heading f-w-600 text-success">
                        DETAIL PERTANDINGAN
                      </h5>
                      <span>
                        <i class="fa fa-calendar me-2"></i>Tanggal :
                        <span
                          ><b>{{ detail_data.tanggal }}</b></span
                        >
                        <br />
                        <i class="fa fa-clock-o me-1"></i> Jam Kickoff :
                        <b> {{ detail_data.jam }} </b>
                      </span>
                      <br />
                      <span>
                        <i class="fa fa-thumb-tack me-1"></i>
                        Lokasi : <b>{{ detail_data.tempat }}</b>
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="text-md-end text-xs-center">
                      <h6>#2024<span class="digits counter"> </span></h6>
                      <p>
                        <span
                          ><b class="text-uppercase">{{
                            detail_data.tipe_pertandingan
                          }}</b></span
                        >
                        <br />
                        <span>
                          <b
                            class="badge badge-warning text-uppercase"
                            v-if="detail_data.status == 'terjadwal'"
                            >{{ detail_data.status }}
                          </b>
                          <b
                            class="badge badge-danger text-uppercase"
                            v-if="detail_data.status == 'dibatalkan'"
                            >{{ detail_data.status }}
                          </b>
                          <b
                            class="badge badge-warning text-uppercase"
                            v-if="detail_data.status == 'ditunda'"
                            >{{ detail_data.status }}
                          </b>
                          <b
                            class="badge badge-primary text-uppercase"
                            v-if="detail_data.status == 'selesai'"
                            >{{ detail_data.status }}
                          </b>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mt-4">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-5 text-end pe-5 pt-5">
                      <div class="border-bottom">
                        <img
                          :src="urlImage + detail_data.skuad?.images"
                          alt=""
                          width="120"
                          height="120"
                          class="text-end p-2"
                        />
                        <h3>
                          <b>{{ detail_data.skuad?.nama_skuad }}</b>
                        </h3>
                      </div>
                    </div>
                    <div class="col-sm-2 text-center bg-primary p-2">
                      <div v-if="detail_data.status == 'selesai'">
                        <h5 class="pt-5">Fulltime</h5>
                        <h1>
                          <b
                            >{{ detail_data.skor }} <span>:</span>
                            {{ detail_data.skor_lawan }}</b
                          >
                        </h1>
                      </div>
                      <div v-else>
                        <h5 class="pt-5 badge text-uppercase">
                          {{ detail_data.status }}
                        </h5>
                        <h1 class="pt-2">VS</h1>
                      </div>
                    </div>
                    <div class="col-sm-5 text-start ps-5 pt-5">
                      <div class="border-bottom">
                        <img
                          :src="urlImage + detail_data.logo_lawan"
                          alt=""
                          width="120"
                          height="120"
                          class="text-end p-2"
                        />
                        <h3>
                          <b>{{ detail_data.tim_lawan }}</b>
                        </h3>
                      </div>
                    </div>

                    <div class="col-sm-5 text-end p-5">
                      <b>{{ detail_data.pencetak_gol }}</b>
                      <i class="fa fa-futbol-o ms-1"></i>
                    </div>
                    <div class="col-sm-2 text-center"></div>
                    <div class="col-sm-5 text-left p-5">
                      <b>{{ detail_data.pencetak_gol_lawan }}</b>
                      <i class="fa fa-futbol-o ms-1"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-4">
                <div class="card-body p-3">
                  <b>Catatan Pertandingan </b> <br />
                  <span>{{ detail_data.keterangan }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <Summary :idd="id" />
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import JadwalService from "@/_services/jadwal.service";
import SettingService from "@/_services/setting.service";
// import Summary from "./summary";
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      id: this.$route.query.idj,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      previewUrl: null,
      logo_tim: null,
      detail_data: [],
      formSubmitted: false,
      validation: false,
    };
  },
  mounted() {
    this.detailDetail();
    this.profilDetail();
  },

  methods: {
    detailDetail() {
      this.loading = true;
      JadwalService.detail(this.id)
        .then((response) => {
          // console.log("data", response.data.data);
          const val = response.data.data;
          this.detail_data = val;
          // this.previewUrl = this.urlImage + val.images;
          // this.nama_skuad = val.nama_skuad;
          // this.staf_pelatih = val.staf_pelatih;
          // this.list_pemain = val.pemain;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    profilDetail() {
      // this.loading = true;
      SettingService.get(this.id)
        .then((response) => {
          console.log("data", response.data.data);
          const val = response.data.data;
          this.logo_tim = val.img;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    cetakPDF() {
      console.log("cetak pdf :", this.id);
    },
  },
};
</script>

<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
