import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class SettingService {
  get() {
    return axios.get(API_URL + 'setting', { headers: authHeader() });
  }

  update(id, data) {
    return axios.post(API_URL + 'setting-update/' + id, data, { headers: authHeader() });
  }

}

export default new SettingService();