import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class KategoriService {
  getPosisi(params) {
    return axios.get(API_URL + 'kat-posisi/' + params, { headers: authHeader() });
  }
  addPosisi(data) {
    return axios.post(API_URL + 'kat-posisi', data, { headers: authHeader() });
  }
  getPosDetail(id) {
    return axios.get(API_URL + 'kat-posisi/' + id, { headers: authHeader() });
  }
  updatePosisi(id, data) {
    return axios.patch(API_URL + 'kat-posisi' + id, data, { headers: authHeader() });
  }
  deletePosisi(id) {
    return axios.delete(API_URL + 'kat-posisi/' + id, { headers: authHeader() });
  }

  getBlog(params) {
    return axios.get(API_URL + 'kat-blog?' + params, { headers: authHeader() });
  }
  getBlogDetail(id) {
    return axios.get(API_URL + 'kat-posisi/' + id, { headers: authHeader() });
  }

  addBlog(data) {
    return axios.post(API_URL + 'kat-blog', data, { headers: authHeader() });
  }

  updateBlog(id, data) {
    return axios.patch(API_URL + 'kat-blog' + id, data, { headers: authHeader() });
  }
  deleteBlog(id) {
    return axios.delete(API_URL + 'kat-blog/' + id, { headers: authHeader() });
  }
}

export default new KategoriService();