import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class PosisiService {
  get() {
    return axios.get(API_URL + 'list-posisi', { headers: authHeader() });
  }
  dropdownPemain() {
    return axios.get(API_URL + 'list-posisi/pemain', { headers: authHeader() });
  }

  dropdownOfisial() {
    return axios.get(API_URL + 'list-posisi/ofisial', { headers: authHeader() });
  }
  
  // add(data) {
  //   return axios.post(API_URL + 'tim-skuad', data, { headers: authHeader() });
  // }
  // dropdown() {
  //   return axios.get(API_URL + 'tim-skuad-dropdown', { headers: authHeader() });
  // }
  // detail(id) {
  //   return axios.get(API_URL + 'tim-skuad/' + id, { headers: authHeader() });
  // }
  // update(id, data) {
  //   return axios.post(API_URL + 'tim-skuad-update/' + id, data, { headers: authHeader() });
  // }
  // delete(id) {
  //   return axios.delete(API_URL + 'tim-skuad/' + id, { headers: authHeader() });
  // }
}

export default new PosisiService();