<template>
    <router-link to="/">
    <!-- <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logo.png"
      width="20"
      alt=""
    /> -->
    <b>AKADEMI SEPAKBOLA</b>
  </router-link>
</template>
<script>

export default {
    
}
</script>