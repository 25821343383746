import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class StatistikService {

  // data statistik
  getTotal() {
    // console.log('header', authHeader);
    return axios.get(API_URL + 'statistik-total', { headers: authHeader() });
  }

  pemainBaru() {
    return axios.get(API_URL + 'statistik-pemain-new', { headers: authHeader() });
  }

  jadwalBaru() {
    return axios.get(API_URL + 'statistik-jadwal-new', { headers: authHeader() });
  }

  dailyPemainUp() {
    return axios.get(API_URL + 'statistik-daily-pemain', { headers: authHeader() });
  }

  totalSkuad() {
    return axios.get(API_URL + 'statistik-total-skuad', { headers: authHeader() });
  }

  totalPendaftaran() {
    return axios.get(API_URL + 'statistik-register-new', { headers: authHeader() });
  }
}

export default new StatistikService();