<template>
  <div class="col-xl-7 box-col-12 des-xl-100 dashboard-sec">
    <div class="card income-card h-100">
      <div class="card-header">
        <div class="">
          <div class="row">
            <div class="col-xl-7">
              <b class="text-uppercase">Aktivitas Penambahan Pemain</b>
            </div>
            <div class="col-xl-5 text-end">
              <span class="font-primary mr-3 f-w-700"
                >+{{ last_total }} Pemain</span
              ><span> Bulan {{ last_month }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="p-4">
          <span style="display: none"> {{ dailyReportSeries }}</span>
          <apexchart
            class="apex-charts pt-5"
            height="320"
            type="area"
            dir="ltr"
            :series="dailyReportSeries"
            :options="{
              chart: {
                id: 'pageview-chart',
                toolbar: {
                  show: false,
                },
              },
              dataLabels: {
                enabled: true,
              },
              stroke: {
                curve: 'smooth',
              },
              colors: ['#24695C'],
              xaxis: {
                type: 'category',
                categories: x_cat,
                labels: {
                  show: true,
                  hideOverlappingLabels: false,
                },
              },
            }"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-5 box-col-12 des-xl-100 dashboard-sec">
    <div class="card h-100">
      <div class="card-header">
        <div class="header-top d-sm-flex align-items-center">
          <b class="text-uppercase">Skuad Total Pemain</b>
        </div>
      </div>
      <div class="card-body apex-chart">
        <div id="piechart">
          <apexchart
            width="400"
            type="pie"
            :options="chartOptions"
            :series="series"
            :responsive="chartOptions.responsive"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-7 mt-3 mb-4">
    <div class="card pb-2">
      <div class="card-body h-100">
        <b class="text-uppercase pb-2">Pemain Baru</b>
        <table class="table table-hover table-striped border pb-0 mt-2">
          <thead>
            <tr>
              <th width="35%">Nama</th>
              <th width="40%">Tempat Tanggal Lahir</th>
              <th>Tim Skuad</th>
              <!-- <th>Detail</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tm, index) in pemain_baru" :key="index">
              <td>
                <!-- <p>{{ tm.nama_tim }}</p> -->
                <div class="media">
                  <img
                    class="img-fluid rounded me-2"
                    :src="url_img + tm.img"
                    alt=""
                    width="30"
                    style="height: 30px"
                  />
                  <div class="media-body">
                    <span>{{ tm.nama }}</span>
                  </div>
                </div>
              </td>
              <td>
                {{ tm.tempat_lahir }}, <br />
                {{ tm.tanggal_lahir }}
              </td>
              <td>
                <span v-if="tm.skuadteam">{{ tm.skuadteam.nama_skuad }}</span>
                <span v-else>-</span>
              </td>
              <!-- <td>
                  <p>
                    <router-link to="#" class="p-0 m-0">Detail </router-link>
                  </p>
                </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="col-sm-5 mt-3 mb-4">
    <div class="card pb-1">
      <div class="card-body h-100">
        <b class="text-uppercase pb-2">Jadwal Terbaru</b> <br />
        <div
          class="border mb-3 mt-2 bg-jadwal"
          v-for="(jd, index) in jadwal_baru"
          :key="index"
        >
          <div class="row p-3">
            <div class="col-sm-6">
              <b class="text-uppercase text-success">{{
                jd.tipe_pertandingan
              }}</b>
            </div>
            <div class="col-sm-6 text-end">
              <b> {{ formatDate(jd.tanggal) }}, {{ jd.jam }} </b><br />
              {{ jd.tempat }}
            </div>
            <div class="col-sm-12 mt-1">
              <span class="">
                {{ jd.skuad?.nama_skuad }}
                <img
                  :src="url_img + jd.skuad?.images"
                  alt=""
                  class="img-fluid ms-1 me-2"
                  width="30"
                />
                <span
                  v-if="jd.status == 'selesai'"
                  class="bg-danger rounded-xs px-1"
                >
                  {{ jd.skor }} - {{ jd.skor_lawan }}
                </span>
                <span v-else class="bg-primary rounded-xs px-1">VS</span>
                <img
                  :src="url_img + jd.logo_lawan"
                  alt=""
                  class="img-fluid me-1 ms-2"
                  width="30"
                />

                {{ jd.tim_lawan }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import StatistikService from "@/_services/statistik.service";
import SettingService from "@/_services/setting.service";
import { watch } from "@vue/runtime-core";
import { ref } from "vue";
import moment from "moment";

var primary = localStorage.getItem("--theme-deafult") || "#24695c";
var secondary = localStorage.getItem("--theme-secondary") || "#ba895d";

export default {
  components: {},

  data() {
    let dailyReportSeries = [
      {
        name: "Daily Statistics",
        data: [],
      },
    ];
    return {
      url_img: process.env.VUE_APP_API_URL_IMAGE,
      pemain_baru: [],
      jadwal_baru: [],
      logo_tim: "",
      series: [],
      last_month: "",
      last_total: "",
      chartOptions: {
        chart: {
          width: 600,
          type: "pie",
        },
        labels: [],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: ["#24695C", "#BA895D", "#717171", "#E2C637", "#D22D3C"],
        legend: {
          show: true,
          position: "bottom",
          labels: {
            colors: "#74788d",
          },
        },
      },
      dailyReportSeries,
      x_cat: [],
      total_skuad_pemain: "",
      label: "",
    };
  },
  methods: {
    profilDetail() {
      // this.loading = true;
      SettingService.get(this.id)
        .then((response) => {
          console.log("data", response.data.data);
          const val = response.data.data;
          this.logo_tim = val.img;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    newPemain() {
      StatistikService.pemainBaru()
        .then((response) => {
          this.pemain_baru = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newJadwal() {
      StatistikService.jadwalBaru()
        .then((response) => {
          console.log("res", response);
          this.jadwal_baru = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDaily() {
      StatistikService.dailyPemainUp()
        .then((response) => {
          this.total_daily = response.data.data;
          response.data.data.forEach((event) => {
            const s = event.month.toString() + " " + event.year.toString();
            this.x_cat.push(s);
            this.dailyReportSeries[0].data.push(event.total);

            this.last_total = event.total;
            this.last_month = event.month;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    skuadTotalPemain() {
      StatistikService.totalSkuad()
        .then((response) => {
          this.total_skuad_pemain = response.data.data;
          console.log("result", response.data.data);
          response.data.data.forEach((event) => {
            this.chartOptions.labels.push(event.nama_skuad);
            this.series.push(event.pemain_count);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
  beforeMount() {
    this.profilDetail();
    this.getDaily();
  },
  mounted() {
    this.skuadTotalPemain();
    this.newJadwal();
    this.newPemain();
  },
};
</script>
<style lang="scss">
.bg-jadwal {
  background-color: #eeeeee;
}
</style>
