<template>
  <div class="container-fluid dashboard-default-sec">
    <div class="row">
      <div class="col-sm-6 col-xl-3 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="align-self-center text-center">
                <i data-feather="grid"
                  ><vue-feather type="grid"></vue-feather
                ></i>
              </div>
              <div class="media-body">
                <b class="m-0">Total Skuad</b>
                <h4 class="mb-0 counter">
                  <number
                    class="bold"
                    ref="number1"
                    :from="0"
                    :to="total_skuad"
                    :duration="3"
                    :delay="0"
                    easing="Power1.easeOut"
                  />
                </h4>
                <vue-feather class="icon-bg" type="grid"></vue-feather>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="align-self-center text-center">
                <i data-feather="globe"
                  ><vue-feather type="users"></vue-feather
                ></i>
              </div>
              <div class="media-body">
                <b class="m-0">Total Pemain</b>
                <h4 class="mb-0 counter">
                  <number
                    class="bold"
                    ref="number1"
                    :from="0"
                    :to="total_pemain"
                    :duration="3"
                    :delay="0"
                    easing="Power1.easeOut"
                  />
                </h4>
                <vue-feather class="icon-bg" type="users"></vue-feather>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="align-self-center text-center">
                <i data-feather="file-text"
                  ><vue-feather type="file-text"></vue-feather
                ></i>
              </div>
              <div class="media-body">
                <b class="m-0">Total Berita</b>
                <h4 class="mb-0 counter">
                  <number
                    class="bold"
                    ref="number1"
                    :from="0"
                    :to="total_berita"
                    :duration="3"
                    :delay="0"
                    easing="Power1.easeOut"
                  />
                </h4>
                <vue-feather class="icon-bg" type="file-text"></vue-feather>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="align-self-center text-center">
                <i data-feather="mail"
                  ><vue-feather type="mail"></vue-feather
                ></i>
              </div>
              <div class="media-body">
                <b class="m-0">Pendaftaran</b>
                <h4 class="mb-0 counter">
                  <number
                    class="bold"
                    ref="number1"
                    :from="0"
                    :to="total_pendaftaran"
                    :duration="3"
                    :delay="0"
                    easing="Power1.easeOut"
                  />
                </h4>
                <vue-feather class="icon-bg" type="mail"></vue-feather>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-xl-5 box-col-12 des-xl-100">
        <div class="row">
          <div class="col-xl-12 col-md-6 box-col-6 des-xl-50">
            <div class="card profile-greeting">
              <div class="card-header">
                <div class="header-top">
                  <div class="setting-list bg-primary position-unset">
                    <ul class="list-unstyled setting-option">
                      <li>
                        <div class="setting-white">
                          <i class="icon-settings"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-body text-center p-t-0">
                <h3 class="font-light">Welcome Back, John!!</h3>
                <p>
                  Welcome to the viho Family! we are glad that you are visite
                  this dashboard. we will be happy to help you grow your
                  business.
                </p>
                <button class="btn btn-light">Update</button>
              </div>
              <div class="confetti">
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
                <div class="confetti-piece"></div>
              </div>
            </div>
          </div>
         
          <annualIncome />
          <annualLosses/>
        </div>
      </div> -->
      <salesOverview />
    </div>
  </div>
</template>

<script>
import salesOverview from "../default/salesOverview.vue";
import VueApexCharts from "vue3-apexcharts";
import StatistikService from "@/_services/statistik.service";
import { watch } from "@vue/runtime-core";
import { ref } from "vue";

export default {
  components: {
    salesOverview,
  },

  data() {
    return {
      total_skuad: "0",
      total_pemain: "0",
      total_berita: "",
      total_daily: "",
      top_wilayah: "",
      label: "",
      total_pendaftaran: "0",
      total_berita: "0",
    };
  },
  methods: {
    getTotalData() {
      // console.log("get total");
      StatistikService.getTotal()
        .then((response) => {
          this.total_skuad = response.data.data.total_skuad;
          this.total_pemain = response.data.data.total_pemain;
          this.total_berita = response.data.data.total_berita;
          // console.log("get total", response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPendaftaran() {
      // console.log("get total");
      StatistikService.totalPendaftaran()
        .then((response) => {
          this.total_pendaftaran = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getTotalData();
    this.getPendaftaran();
  },
};
</script>
