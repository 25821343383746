import { createRouter, createWebHistory } from 'vue-router'
import BodyView from '../layout/BodyView.vue'
import auth from "../auth/auth.vue"
import IndexDefault from '../components/dashboard/default/IndexDefault.vue'
import errorPage1 from '../errors/errorPage1.vue';
// import vue_leaflet from "../components/maps/vue_leaflet"
import login from '../auth/login';
import maintenance from "../components/maintenance"
import skuad_page from "../components/skuad/index"
import skuad_edit from "../components/skuad/edit"
import skuad_add from "../components/skuad/tambah"
import skuad_detail from "../components/skuad/detail"
import berita_page from "../components/berita/index"
import berita_edit from "../components/berita/edit"
import berita_add from "../components/berita/tambah"
import agenda_page from "../components/agenda/index"
import agenda_edit from "../components/agenda/edit"
import agenda_add from "../components/agenda/tambah"
import agenda_detail from "../components/agenda/detail"
import jadwal_page from "../components/jadwal/index"
import user_page from "../components/users/index"
import user_edit from "../components/users/edit"
import user_add from "../components/users/tambah"
import reset_password_user from "../components/users/reset-password"
import pemain_page from "../components/pemain/index"
import pemain_edit from "../components/pemain/edit"
import pemain_detail from "../components/pemain/detail"
import pemain_detail_report from "../components/pemain/detail-report"
import pemain_add from "../components/pemain/tambah"
import ofisial_page from "../components/ofisial/index"
import ofisial_edit from "../components/ofisial/edit"
import ofisial_add from "../components/ofisial/tambah"
import jadwal_edit from "../components/jadwal/edit"
import jadwal_add from "../components/jadwal/tambah"
import jadwal_detail from "../components/jadwal/detail"
import jadwal_detail_dsp from "../components/jadwal/summary"
import katposisi_page from "../components/kategoriPosisi/index"
import katposisi_edit from "../components/kategoriPosisi/edit"
import katposisi_add from "../components/kategoriPosisi/tambah"
import sponsor_page from "../components/sponsor/index"
import sponsor_add from "../components/sponsor/tambah"
import katblog_page from "../components/kategoriBlog/index"
import katblog_add from "../components/kategoriBlog/tambah"
import setting from "../components/setting/index"

import register_pemain_page from "../components/registerpemain/index"
import register_pemain_edit from "../components/registerpemain/edit"
import register_pemain_detail from "../components/registerpemain/detail"

const routes = [
  {
    path: '/',
    component: BodyView,
    children: [
      {
        path: '',
        name: 'defaultRoot',
        component: IndexDefault,
      },
    ]
  },
  {
    path: '/dashboard',
    component: BodyView,

    children: [
      {
        path: 'default',
        name: 'defaultIndex',
        component: IndexDefault,
      },

    ]
  },
  {
    path: '/error-page1',
    name: 'errorPage1',
    component: errorPage1,
  },

  {
    path: "/maintenance",
    name: "maintenance",
    component: maintenance
  },

  {
    path: "/pages",
    component: BodyView,
    children: [
      {
        path: "skuad",
        name: "skuad",
        component: skuad_page
      },
      {
        path: "skuad-tambah",
        name: "skuad-tambah",
        component: skuad_add
      },
      {
        path: "skuad-ubah",
        name: "skuad-ubah",
        component: skuad_edit
      },
      {
        path: "skuad-detail",
        name: "skuad-detail",
        component: skuad_detail
      },
      {
        path: "pemain",
        name: "pemain",
        component: pemain_page
      },
      {
        path: "pemain-detail",
        name: "pemain-detail",
        component: pemain_detail
      },
      {
        path: "pemain-detail-report",
        name: "pemain-detail-report",
        component: pemain_detail_report
      },
      {
        path: "pemain-tambah",
        name: "pemain-tambah",
        component: pemain_add
      },
      {
        path: "pemain-ubah",
        name: "pemain-ubah",
        component: pemain_edit
      },

      {
        path: "register-pemain",
        name: "register-pemain",
        component: register_pemain_page
      },
      {
        path: "register-pemain-detail",
        name: "register-pemain-detail",
        component: register_pemain_detail
      },
      {
        path: "register-pemain-ubah",
        name: "register-pemain-ubah",
        component: register_pemain_edit
      },

      {
        path: "ofisial",
        name: "ofisial",
        component: ofisial_page
      },
      {
        path: "ofisial-tambah",
        name: "ofisial-tambah",
        component: ofisial_add
      },
      {
        path: "ofisial-ubah",
        name: "ofisial-ubah",
        component: ofisial_edit
      },
      {
        path: "katposisi",
        name: "katposisi",
        component: katposisi_page
      },
      {
        path: "katposisi-tambah",
        name: "katposisi-tambah",
        component: katposisi_add
      },
      {
        path: "katposisi-ubah",
        name: "katposisi-ubah",
        component: katposisi_edit
      },

      {
        path: "sponsor",
        name: "sponsor",
        component: sponsor_page
      },
      {
        path: "sponsor-tambah",
        name: "sponsor-tambah",
        component: sponsor_add
      },

      {
        path: "katblog",
        name: "katblog",
        component: katblog_page
      },
      {
        path: "katblog-tambah",
        name: "katblog-tambah",
        component: katblog_add
      },
      {
        path: "berita",
        name: "berita",
        component: berita_page
      },
      {
        path: "berita-tambah",
        name: "berita-tambah",
        component: berita_add
      },
      {
        path: "berita-ubah",
        name: "berita-ubah",
        component: berita_edit
      },

      {
        path: "agenda",
        name: "agenda",
        component: agenda_page
      },
      {
        path: "agenda-tambah",
        name: "agenda-tambah",
        component: agenda_add
      },
      {
        path: "agenda-edit",
        name: "agenda-edit",
        component: agenda_edit
      },
      {
        path: "agenda-detail",
        name: "agenda-detail",
        component: agenda_detail
      },
      {
        path: "jadwal",
        name: "jadwal",
        component: jadwal_page
      },
      {
        path: "jadwal-tambah",
        name: "jadwal-tambah",
        component: jadwal_add
      },
      {
        path: "jadwal-detail",
        name: "jadwal-detail",
        component: jadwal_detail
      },
      {
        path: "jadwal-detail-dsp",
        name: "jadwal-detail-dsp",
        component: jadwal_detail_dsp
      },
      {
        path: "jadwal-ubah",
        name: "jadwal-ubah",
        component: jadwal_edit
      },
      {
        path: "user",
        name: "user",
        component: user_page
      },
      {
        path: "user-tambah",
        name: "user-tambah",
        component: user_add
      },
      {
        path: "user-ubah",
        name: "user-ubah",
        component: user_edit
      },
      {
        path: "reset-password-user",
        name: "reset-password-user",
        component: reset_password_user
      },
      {
        path: "setting",
        name: "setting",
        component: setting
      },

    ]
  },

  {
    path: "/auth",
    component: auth,
    children: [
      {
        path: "login",
        name: "login",
        component: login
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('auth/login');
  }
  next();
})

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
//   linkExactActiveClass: 'active',
// })

export default router
