<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/agenda">Agenda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Tambah Agenda
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/agenda">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6><b>Data Agenda</b></h6>
          </div>
          <hr />
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-12 position-relative">
                <b for="validationTooltip01">Judul</b>
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? judulError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="judul"
                  placeholder="Judul"
                  v-model="judul"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[0] && formSubmitted"
                >
                  {{ errors[0].message }}
                </small>
              </div>

              <div class="col-md-12 position-relative">
                <b for="validationTooltip20">Tipe</b>
                <select
                  name="type"
                  id="type"
                  class="form-control"
                  v-model="type"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted ? (typeError ? 'is-invalid' : 'is-valid') : ''
                  "
                >
                  <option value="Kegiatan">Kegiatan</option>
                  <option value="Pertandingan">Pertandingan</option>
                  <option value="Lainya">Lainya</option>
                </select>
                <small
                  class="align-end text-danger"
                  v-if="errors[1] && formSubmitted"
                >
                  {{ errors[1].message }}
                </small>
              </div>

              <div class="col-md-12 position-relative">
                <b for="validationTooltip01">Keterangan</b>
                <textarea
                  name="ket"
                  class="form-control"
                  cols="30"
                  rows="3"
                  placeholder="Keterangan"
                  v-model="keterangan"
                ></textarea>
              </div>

              <div class="col-md-4 position-relative">
                <b
                  >Foto Sampul <span class="text-danger">*Maksimal 4Mb</span></b
                >
                <input
                  class="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  @change="imageskuad"
                  accept="image/*"
                  v-bind:class="
                    formSubmitted ? (!images ? 'is-invalid' : 'is-valid') : ''
                  "
                />
                <small class="text-danger" v-if="!images && formSubmitted">
                  Foto Sampul Agenda harus di isi !
                </small>
                <div>
                  <img :src="previewUrl" class="rounded mr-4 mt-4 w-50" />
                </div>
              </div>
              <div class="col-12 text-end">
                <button class="btn btn-light" @click="resetForm" type="button">
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanAgenda"
                  type="button"
                >
                  <span v-if="loading">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="tempat_lahir"
                    >
                      <span class="sr-only"></span>
                    </div>
                    Loading ..
                  </span>
                  <span v-else> Simpan </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AgendaService from "@/_services/agenda.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      images: "",
      previewUrl: null,
      imagesError: false,
      errors: [],
      formSubmitted: false,
      judul: "",
      keterangan: "",
      judulError: false,
      type: "",
      typeError: false,
      validation: false,
      editor: ClassicEditor,
    };
  },

  methods: {
    imageskuad(evt) {
      this.images = evt.target.files[0];
      this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.judul.length < 1) {
        this.judulError = true;
        this.errors.push({
          message: "Judul harus di isi !",
        });
      } else {
        this.judulError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.type.length < 1) {
        this.typeError = true;
        this.errors.push({
          message: "Tipe harus di isi !",
        });
      } else {
        this.typeError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.images.length < 1) {
        this.imagesError = true;
        this.errors.push({
          message: "Foto galeri harus di isi !",
        });
      } else {
        this.imagesError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanAgenda() {
      this.formSubmitted = true;
      this.validationForm();

      if (this.judul && this.type && this.images) {
        this.validation = true;
        this.loading = true;
        const formData = new FormData();
        formData.append("judul", this.judul);
        formData.append("type", this.type);
        formData.append("keterangan", this.keterangan);
        formData.append("image", this.images);
        AgendaService.add(formData)
          .then((response) => {
            // console.log("berhasil", response.data);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Data Berhasil Ditambah",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading = false;
            this.$router.push("/pages/agenda");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Data Gagal Ditambah",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    resetForm() {
      // console.log("rseset ");
      this.loading = false;
      this.images = "";
      this.previewUrl = null;
      this.imagesError = false;
      this.errors = [];
      this.formSubmitted = false;
      this.judul = "";
      this.judulError = false;
      this.type = "";
      this.typeError = false;
      this.validation = false;
    },
  },
};
</script>

<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}

.form-control {
  border: #a8a7a7 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.7px solid;
}
</style>
