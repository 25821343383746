import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class SkuadService {
  get(params) {
    return axios.get(API_URL + 'tim-skuad?' + params, { headers: authHeader() });
  }
  add(data) {
    return axios.post(API_URL + 'tim-skuad', data, { headers: authHeader() });
  }
  dropdown() {
    return axios.get(API_URL + 'tim-skuad-dropdown', { headers: authHeader() });
  }
  detail(id) {
    return axios.get(API_URL + 'tim-skuad/' + id, { headers: authHeader() });
  }
  update(id, data) {
    return axios.post(API_URL + 'tim-skuad-update/' + id, data, { headers: authHeader() });
  }
  delete(id) {
    return axios.delete(API_URL + 'tim-skuad/' + id, { headers: authHeader() });
  }

  listPemain(id) {
    return axios.get(API_URL + 'pemain-skuad/' + id, { headers: authHeader() });
  }

  listPemainAdd(id) {
    return axios.get(API_URL + 'pemain-skuad-add/' + id, { headers: authHeader() });
  }
}

export default new SkuadService();