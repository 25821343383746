<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/user">User</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit User
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/user">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Data User</h6>
          </div>

          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-6 position-relative">
                <label class="form-label" for="validationTooltip01">Nama</label>
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted ? (nameError ? 'is-invalid' : 'is-valid') : ''
                  "
                  id="name"
                  placeholder="Nama"
                  v-model="name"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[0] && formSubmitted"
                >
                  {{ errors[0].message }}
                </small>
              </div>
              <div class="col-md-6 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >Email</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? emailError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="email"
                  placeholder="Email"
                  v-model="email"
                />
                <small class="text-danger" v-if="errors[1] && formSubmitted">
                  {{ errors[1].message }}
                </small>
              </div>
              <div class="col-md-6 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >Nomer Hp</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? no_hpError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="no_hp"
                  placeholder="Nomer Hp"
                  v-model="no_hp"
                />
                <small class="text-danger" v-if="errors[3] && formSubmitted">
                  {{ errors[3].message }}
                </small>
              </div>
              <div class="col-md-6 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >Role Akses</label
                >
                <select
                  type="text"
                  class="form-control"
                  id="jabatan_tim"
                  v-model="jabatan_tim"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? jabatan_timError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">Pilih Role</option>
                  <option value="superadmin">Superadmin</option>
                  <option value="admin">Admin</option>
                  <option value="reporter">Reporter</option>
                  <option value="viewer">Viewer</option>
                </select>
                <small class="text-danger" v-if="errors[2] && formSubmitted">
                  {{ errors[2].message }}
                </small>
              </div>

              <div class="col-12 text-end">
                <button class="btn btn-light" @click="resetForm" type="button">
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanUsers"
                  type="button"
                >
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import UserAdminServiceService from "@/_services/user-admin.service.";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.idu,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      errors: [],
      formSubmitted: false,
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      jabatan_tim: "",
      jabatan_timError: false,
      no_hp: "",
      no_hpError: false,
      validation: false,

      editor: ClassicEditor,
    };
  },
  mounted() {
    this.detailDetail("get");
  },

  methods: {
    detailDetail(type) {
      if (type !== "reset") {
        this.loading = true;
      }
      UserAdminServiceService.detail(this.id)
        .then((response) => {
          console.log("data", response.data.data);
          const val = response.data.data;
          this.previewUrl = this.urlImage + val.images;
          this.name = val.name;
          this.email = val.email;
          this.jabatan_tim = val.jabatan_tim;
          this.no_hp = val.no_hp;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    validationForm() {
      this.errors = [];
      if (this.name.length < 1) {
        this.nameError = true;
        this.errors.push({
          message: "Nama harus di isi !",
        });
      } else {
        this.nameError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.email.length < 1) {
        this.emailError = true;
        this.errors.push({
          message: "Email harus di isi !",
        });
      } else {
        this.emailError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.jabatan_tim.length < 1) {
        this.jabatan_timError = true;
        this.errors.push({
          message: "Role akses harus di isi !",
        });
      } else {
        this.jabatan_timError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.no_hp.length < 1) {
        this.no_hpError = true;
        this.errors.push({
          message: "Nomer Hp harus di isi !",
        });
      } else {
        this.noError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanUsers() {
      this.formSubmitted = true;
      this.validationForm();

      if (this.name && this.email && this.no_hp && this.jabatan_tim) {
        this.validation = true;
        const data = {
          name: this.name,
          email: this.email,
          no_hp: this.no_hp,
          jabatan_tim: this.jabatan_tim,
        };
        console.log("data", data);

        UserAdminServiceService.update(this.id, data)
          .then((response) => {
            console.log("berhasil", response.data);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Data Berhasil Diupdate",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading = false;
            this.$router.push("/pages/user");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Data Gagal Diupdate",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    resetForm() {
      console.log("rseset ");
      this.loading = false;
      this.errors = [];
      this.formSubmitted = false;
      this.name = "";
      this.nameError = false;
      this.email = "";
      this.emailError = false;
      this.jabatan_tim = "";
      this.jabatan_timError = false;
      this.no_hp = "";
      this.no_hpError = false;
      this.validation = false;
      this.detailDetail("reset");
    },
  },
};
</script>

<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.form-control {
  border: #a8a7a7 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.7px solid;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
