<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/berita">Berita</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit Berita
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/berita">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Data Berita</h6>
          </div>
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-12 position-relative">
                <label class="form-label" for="validationTooltip01"
                  >Judul</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? judulError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="judul"
                  placeholder="Judul Berita"
                  v-model="judul"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[0] && formSubmitted"
                >
                  {{ errors[0].message }}
                </small>
              </div>
              <div class="col-md-12 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >Sub Judul</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? subJudulError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="sub_judul"
                  placeholder="Sub Judul"
                  v-model="sub_judul"
                />
                <small class="text-danger" v-if="errors[1] && formSubmitted">
                  {{ errors[1].message }}
                </small>
              </div>
              <div class="col-md-4 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >Kategori</label
                >
                <select
                  type="text"
                  class="form-control"
                  id="kategori"
                  v-model="kategori"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? kategoriError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="">Pilih Kategori</option>
                  <option value="1">Umum</option>
                  <option value="2">Tim Utama</option>
                  <option value="3">Tim Junior</option>
                  <option value="4">Akademi</option>
                </select>
                <small class="text-danger" v-if="errors[2] && formSubmitted">
                  {{ errors[2].message }}
                </small>
              </div>
              <div class="col-md-8 position-relative">
                <label class="form-label" for="validationTooltip02">Tag</label>
                <input
                  type="text"
                  class="form-control"
                  id="tag"
                  placeholder="Tag Berita"
                  v-model="tag"
                />
              </div>
              <div class="col-md-12 position-relative">
                <label class="form-label" for="validationTooltip03"
                  >Isi Berita</label
                >
                <ckeditor :editor="editor" v-model="isiBerita"> </ckeditor>
                <small class="text-danger" v-if="!isiBerita && formSubmitted">
                  Isi berita harus di isi !
                </small>
              </div>

              <div class="col-md-4 position-relative">
                <label for="formFileSm" class="form-label">Foto Sampul</label>
                <input
                  class="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  @change="imageberita"
                  accept="image/*"
                />

                <div>
                  <img :src="previewUrl" class="rounded mr-4 mt-4 w-50" />
                </div>
              </div>
              <div class="col-12 text-end">
                <button
                  class="btn btn-light"
                  @click="detailDetail"
                  type="button"
                >
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanBerita"
                  type="button"
                >
                  <span v-if="loading">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="tempat_lahir"
                    >
                      <span class="sr-only"></span>
                    </div>
                    Loading ..
                  </span>
                  <span v-else> Simpan </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BeritaService from "@/_services/berita.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  // components: { Vue3EasyDataTable },
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.idb,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      image_berita: "",
      previewUrl: null,
      image_beritaError: false,
      errors: [],
      formSubmitted: false,
      judul: "",
      judulError: false,
      sub_judul: "",
      subJudulError: false,
      kategori: "",
      kategoriError: false,
      tag: "",
      isiBerita: "",
      isiBeritaError: false,
      validation: false,
      editor: ClassicEditor,
    };
  },
  mounted() {
    this.detailDetail();
  },

  methods: {
    detailDetail() {
      // this.loading = true;
      BeritaService.detail(this.id)
        .then((response) => {
          // console.log("data", response.data.data);
          const val = response.data.data;
          this.previewUrl = this.urlImage + val.images;
          this.judul = val.judul;
          this.sub_judul = val.sub_description;
          this.kategori = val.kategori_id;
          this.tag = val.tag;
          this.isiBerita = val.isi_berita;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    imageberita(evt) {
      this.image_berita = evt.target.files[0];
      this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.judul.length < 1) {
        this.judulError = true;
        this.errors.push({
          message: "Judul harus di isi !",
        });
      } else {
        this.judulError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.sub_judul.length < 1) {
        this.subJudulError = true;
        this.errors.push({
          message: "Sub Judul harus di isi !",
        });
      } else {
        this.subJudulError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.kategori.length < 1) {
        this.kategoriError = true;
        this.errors.push({
          message: "Kategori harus di isi !",
        });
      } else {
        this.kategoriError = false;
        this.errors.push({
          message: "",
        });
      }
      if (this.isiBerita.length < 1) {
        this.isiBeritaError = true;
        this.errors.push({
          message: "isi berita harus di isi !",
        });
      } else {
        this.isiBeritaError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanBerita() {
      this.formSubmitted = true;
      this.validationForm();

      if (this.judul && this.sub_judul && this.isiBerita && this.kategori) {
        this.validation = true;
        this.loading = true;

        if (this.image_berita) {
          const formData = new FormData();
          formData.append("judul", this.judul);
          formData.append("sub_description", this.sub_judul);
          formData.append("isi_berita", this.isiBerita);
          formData.append("images", this.image_berita);
          formData.append("kategori_id", this.kategori);
          formData.append("tag", this.tag);

          BeritaService.update(this.id, formData)
            .then((response) => {
              // console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              // this.$router.push("/pages/berita");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          const formData = new FormData();
          formData.append("judul", this.judul);
          formData.append("sub_description", this.sub_judul);
          formData.append("isi_berita", this.isiBerita);
          formData.append("kategori_id", this.kategori);
          formData.append("tag", this.tag);

          BeritaService.update(this.id, formData)
            .then((response) => {
              // console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              // this.$router.push("/pages/berita");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    },

    resetForm() {
      // console.log("rseset ");
      this.loading = false;
      this.image_berita = "";
      this.previewUrl = null;
      this.image_beritaError = false;
      this.errors = [];
      this.formSubmitted = false;
      this.judul = "";
      this.judulError = false;
      this.sub_judul = "";
      this.subJudulError = false;
      this.kategori = "";
      this.kategoriError = false;
      this.tag = "";
      this.isiBerita = "";
      this.isiBeritaError = false;
      this.validation = false;
    },
  },
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
