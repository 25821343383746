<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/skuad">Skuad</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              List Pemain
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/skuad">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div>
                <div class="row invo-header">
                  <div class="col-sm-6">
                    <div>
                      <h4 class="media-heading f-w-600">{{ nama_skuad }}</h4>
                      <span
                        >Staf Pelatih :
                        <span class="digits">{{ staf_pelatih }}</span> <br
                      /></span>
                      <span
                        >Total Pemain :
                        <span class="digits">{{
                          list_pemain.length
                        }}</span></span
                      >
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="text-md-end text-xs-center">
                      <h5>
                        Statistik<span class="digits counter">
                          <!-- <number
                        class="bold counter"
                        ref="number1"
                        :from="0"
                        :to="1069"
                        :duration="5"
                        :delay="0"
                        easing="Power1.easeOut"
                      /> -->
                        </span>
                      </h5>
                      <p>
                        Pertandingan<span class="digits"> : -</span><br />Menang
                        : <span class="digits">-</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="table-responsive mt-3">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <td width="4%" class="text-center">No</td>
                        <td class="text-center" width="5%">Foto</td>
                        <td>Nama Pemain</td>
                        <td>Tempat Tanggal Lahir</td>
                        <td width="5%" class="text-center">Umur</td>
                        <td width="25%">Posisi</td>
                        <td width="8%">Aksi</td>
                      </tr>
                    </thead>
                    <tbody v-if="list_pemain[0]">
                      <tr v-for="(pmn, index) in list_pemain" :key="pmn.id">
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">
                          <img
                            :src="urlImage + pmn.img"
                            class="rounded w-75"
                            height="35"
                          />
                        </td>
                        <td>{{ pmn.nama }}</td>
                        <td>{{ pmn.tempat_lahir }}, {{ pmn.tanggal_lahir }}</td>
                        <td class="text-center">{{ pmn.umur }}</td>
                        <td>{{ pmn.posisi.nama_posisi }}</td>
                        <td>
                          <router-link
                            :to="'pemain-detail?idp=' + pmn.id"
                            class="mr-1"
                          >
                            <b-button
                              title="Edit pemain"
                              class="btn btn-sm px-2 py-1 me-1"
                              variant="primary"
                              ><i class="fa fa-eye"></i
                            ></b-button>
                          </router-link>
                          <b-button
                            @click="deletePemain(pmn.id, pmn.nama, pmn)"
                            title="Hapus Pemain"
                            class="btn btn-sm px-2 py-1 ml-1"
                            variant="danger"
                            ><i class="fa fa-trash"></i
                          ></b-button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td class="text-center" colspan="7">
                          Tidak ada pemain di skuad ini !
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-sm-6 mt-3 mr-0 pr-0">
                  <label for="">Tambah Pemain</label>
                  <select
                    type="text"
                    class="form-control"
                    id="pemain_add_id"
                    v-model="pemain_add_id"
                  >
                    <option value="">- Pilih Pemain -</option>
                    <option
                      :value="pmn.id"
                      v-for="pmn in list_pemain_add"
                      :key="pmn.id"
                    >
                      {{ pmn.nama }} | {{ pmn.tempat_lahir }} |
                      {{ pmn.tanggal_lahir }} | {{ pmn.posisi.nama_posisi }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-6 text-start mt-3 ml-0 pl-0">
                  <label for="" class="text-white">-&nbsp;</label> <br />
                  <button
                    class="btn btn btn-primary me-1"
                    type="button"
                    @click="addPemain()"
                    :disabled="!pemain_add_id"
                    v-if="pemain_add_id"
                  >
                    Tambah
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import SkuadService from "@/_services/skuad.service";
import PemainService from "@/_services/pemain.service";
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      id: this.$route.query.ids,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      previewUrl: null,
      list_pemain_add: [],
      list_pemain: [],
      pemain_add_id: "",
      formSubmitted: false,
      nama_skuad: "",
      staf_pelatih: "",
      validation: false,
    };
  },
  mounted() {
    this.detailDetail();
    this.listPemainSKuad();
    this.listPemainAdd();
  },

  methods: {
    detailDetail() {
      this.loading = true;
      SkuadService.detail(this.id)
        .then((response) => {
          // console.log("data", response.data.data);
          const val = response.data.data;
          this.previewUrl = this.urlImage + val.images;
          this.nama_skuad = val.nama_skuad;
          this.staf_pelatih = val.staf_pelatih;
          // this.list_pemain = val.pemain;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    listPemainSKuad() {
      // this.loading = true;
      SkuadService.listPemain(this.id)
        .then((response) => {
          // console.log("data pemain", response.data.data);
          const val = response.data.data;
          // this.previewUrl = this.urlImage + val.images;
          // this.nama_skuad = val.nama_skuad;
          // this.staf_pelatih = val.staf_pelatih;
          this.list_pemain = val;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    listPemainAdd() {
      // this.loading = true;
      SkuadService.listPemainAdd(this.id)
        .then((response) => {
          // console.log("data pemain", response.data.data);
          this.list_pemain_add = response.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    deletePemain(id, name, data) {
      const pmn = data;
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus pemain dari skuad ini?",
        text: name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#153F37",
        cancelButtonColor: "#eeeeee",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("id", id);
          const formData = new FormData();
          formData.append("team_skuad_id", null);
          formData.append("nama", pmn.nama);
          formData.append("tempat_lahir", pmn.tempat_lahir);
          formData.append("tanggal_lahir", pmn.tanggal_lahir);
          formData.append("no_punggung", pmn.no_punggung);
          formData.append("umur", pmn.umur);
          formData.append("posisi", pmn.posisi.id);
          formData.append("statistik", pmn.statistik);
          formData.append("keterangan", pmn.keterangan);

          // console.log("fd no image", FormData);
          PemainService.update(id, formData)
            .then((response) => {
              // console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              this.listPemainSKuad();
              this.listPemainAdd();
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    addPemain() {
      const data = {
        id: this.pemain_add_id,
        team_skuad_id: this.id,
      };
      PemainService.updatePemainSkuad(data)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Data Berhasil Diupdate",
            showConfirmButton: false,
            timer: 1500,
          });
          this.listPemainSKuad();
          this.listPemainAdd();
          this.pemain_add_id = "";
          this.loading = false;
          //  this.$router.push("/pages/pemain");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Data Gagal Diupdate",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  },
};
</script>

<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
