<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/user">User</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Reset Password
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/user">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header pb-0">
            <h6>Reset Password</h6>
          </div>

          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-12 position-relative">
                <label class="form-label" for="validationTooltip01"
                  >Password Baru</label
                >
                <input
                  type="password"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? passwordError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="password"
                  placeholder="Password Baru"
                  v-model="password"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[0] && formSubmitted"
                >
                  {{ errors[0].message }}
                </small>
              </div>

              <div class="col-12 text-end">
                <button class="btn btn-light" @click="resetForm" type="button">
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanUsers"
                  type="button"
                >
                  Simpan
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import UserAdminServiceService from "@/_services/user-admin.service.";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.idu,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      errors: [],
      formSubmitted: false,
      name: "",
      email: "",
      password: "",
      passwordError: false,

      validation: false,

      editor: ClassicEditor,
    };
  },
  mounted() {
    this.detailDetail();
  },

  methods: {
    detailDetail() {
      this.loading = true;
      UserAdminServiceService.detail(this.id)
        .then((response) => {
          console.log("data", response.data.data);
          const val = response.data.data;
          this.name = val.name;
          this.email = val.email;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    validationForm() {
      this.errors = [];
      if (this.password.length < 6) {
        this.passwordError = true;
        this.errors.push({
          message: "Password harus di isi & minimal 6 karakter !",
        });
      } else {
        this.passwordError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanUsers() {
      this.formSubmitted = true;
      this.validationForm();

      if (this.password.length > 5 && this.email) {
        this.validation = true;
        const data = {
          email: this.email,
          password: this.password,
        };
        console.log("data", data);

        UserAdminServiceService.resetPassword(data)
          .then((response) => {
            console.log("berhasil", response.data);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Data Berhasil Diupdate",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading = false;
            this.$router.push("/pages/user");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Data Gagal Diupdate",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },

    resetForm() {
      console.log("rseset ");
      this.loading = false;
      this.errors = [];
      this.formSubmitted = false;
      this.password = "";
      this.passwordError = false;
      this.validation = false;
    },
  },
};
</script>

<style scoped>
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
