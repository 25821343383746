<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-b="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item"><a href="/pages/skuad">Skuad</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit Skuad
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/skuad">
          <b-button variant="danger" class="btn btn-add btn-sm mb-2 px-4">
            <i class="fa fa-arrow-left mr-1"></i> &nbsp; Kembali</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <!-- <div class="card-header pb-0">
            <h6>Data Skuad</h6>
          </div> -->
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-md-12 position-relative">
                <label class="mb-1" for="validationTooltip01"
                  >Nama Skuad
                </label>
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? nama_skuadError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="nama_skuad"
                  placeholder="nama skuad"
                  v-model="nama_skuad"
                />
                <small
                  class="align-end text-danger"
                  v-if="errors[0] && formSubmitted"
                >
                  {{ errors[0].message }}
                </small>
              </div>
              <div class="col-md-12 position-relative">
                <label class="mb-1" for="validationTooltip02"
                  >Staf Pelatih</label
                >
                <textarea
                  type="text"
                  class="form-control"
                  id="staf_pelatih"
                  placeholder="staf pelatih"
                  v-model="staf_pelatih"
                />
              </div>

              <div class="col-md-4 position-relative">
                <label for="formFileSm" class="mb-1">Logo Tim</label>
                <input
                  class="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  @change="imageskuad"
                  accept="image/*"
                />

                <div>
                  <img :src="previewUrl" class="rounded mr-4 mt-4 w-50" />
                </div>
              </div>
              <div class="col-12 text-end">
                <button
                  class="btn btn-light"
                  @click="detailDetail"
                  type="button"
                >
                  Reset
                </button>
                &nbsp;
                <button
                  class="btn btn-primary"
                  @click="simpanskuad"
                  type="button"
                >
                  <span v-if="loading">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="tempat_lahir"
                    >
                      <span class="sr-only"></span>
                    </div>
                    Loading ..
                  </span>
                  <span v-else> Simpan </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import skuadService from "@/_services/skuad.service";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DropZone from "dropzone-vue";

export default {
  components: {
    ckeditor: CKEditor.component,
    DropZone,
  },
  data() {
    return {
      id: this.$route.query.ids,
      urlImage: process.env.VUE_APP_API_URL_IMAGE,
      loading: false,
      image_skuad: "",
      previewUrl: null,
      image_skuadError: false,
      errors: [],
      formSubmitted: false,
      nama_skuad: "",
      nama_skuadError: false,
      staf_pelatih: "",
      validation: false,

      editor: ClassicEditor,
    };
  },
  mounted() {
    this.detailDetail();
  },

  methods: {
    detailDetail() {
      this.loading = true;
      skuadService
        .detail(this.id)
        .then((response) => {
          console.log("data", response.data.data);
          const val = response.data.data;
          this.previewUrl = this.urlImage + val.images;
          this.nama_skuad = val.nama_skuad;
          this.staf_pelatih = val.staf_pelatih;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    imageskuad(evt) {
      this.image_skuad = evt.target.files[0];
      this.previewUrl = URL.createObjectURL(evt.target.files[0]);
    },

    validationForm() {
      this.errors = [];
      if (this.nama_skuad.length < 1) {
        this.nama_skuadError = true;
        this.errors.push({
          message: "nama_skuad harus di isi !",
        });
      } else {
        this.nama_skuadError = false;
        this.errors.push({
          message: "",
        });
      }
    },

    simpanskuad() {
      this.formSubmitted = true;
      this.validationForm();

      if (this.nama_skuad) {
        this.validation = true;
        this.loading = true;

        if (this.image_skuad) {
          console.log("image yees");
          const formData = new FormData();
          formData.append("nama_skuad", this.nama_skuad);
          formData.append("staf_pelatih", this.staf_pelatih);
          formData.append("image", this.image_skuad);

          skuadService
            .update(this.id, formData)
            .then((response) => {
              console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              this.$router.push("/pages/skuad");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          const formData = new FormData();
          formData.append("nama_skuad", this.nama_skuad);
          formData.append("staf_pelatih", this.staf_pelatih);

          skuadService
            .update(this.id, formData)
            .then((response) => {
              console.log("berhasil", response.data);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
              this.$router.push("/pages/skuad");
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Data Gagal Diupdate",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    },

    resetForm() {
      console.log("rseset ");
      this.loading = false;
      this.image_skuad = "";
      this.previewUrl = null;
      this.image_skuadError = false;
      this.errors = [];
      this.formSubmitted = false;
      this.nama_skuad = "";
      this.nama_skuadError = false;
      this.staf_pelatih = "";
      this.validation = false;
    },
  },
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
