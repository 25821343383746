<template>
  <div class="container-fluid p-0 bgimage">
    <div class="row rounded">
      <div class="col-12">
        <div class="login-card">
          <!-- <h4 class="text-center">ok</h4> -->
          <form class="theme-form login-form rounded p-4">
            <h4>Login</h4>
            <h6>Dashboard Akademi Sepakbola</h6>
            <div
              class="alert alert-danger danger alert-dismissible fade show"
              role="alert"
              v-if="message && submitted"
            >
              {{ message }} !!
              <button
                class="btn-close"
                type="button"
                @click="closeAlert"
              ></button>
            </div>
            <div class="form-group">
              <label>Email</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-email"></i></span>
                <input
                  v-model="email"
                  class="form-control border"
                  type="email"
                  required=""
                  placeholder="email"
                  :class="{ 'is-invalid': submitted && !email }"
                />
                <div
                  v-show="submitted && !email"
                  class="invalid-feedback bg-danger py-1 px-3"
                >
                  Email harus di isi !
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-lock"></i></span>
                <input
                  v-model="password"
                  autocomplete=""
                  class="form-control"
                  :type="type"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  :class="{ 'is-invalid': submitted && !password }"
                />
                <div
                  v-show="submitted && !password"
                  class="invalid-feedback bg-danger py-1 px-3"
                >
                  Password Harus di isi !
                </div>
                <div class="show-hide">
                  <span class="m-l-5" @click="showPassword">
                    <i class="fa fa-eye"></i
                  ></span>
                </div>
              </div>
            </div>

            <div class="btn-block">
              <button
                class="btn btn-primary btn-block"
                type="button"
                @click="handleSubmit"
              >
                <span v-if="!loading">Login</span>
                <span v-if="loading">
                  <b-spinner small label="Small Spinner"></b-spinner>
                  Loading..</span
                >
              </button>
            </div>
            <div class="login-social-title mt-3">
              <h5>Social Media</h5>
            </div>
            <div class="form-group">
              <ul class="login-social">
                <li>
                  <a href="" target="_blank"><i class="fa fa-google"></i></a>
                </li>
                <li>
                  <a href="" target="_blank"
                    ><i data-feather="twitter"
                      ><vue-feather type="twitter"></vue-feather></i
                  ></a>
                </li>
                <li>
                  <a href="" target="_blank"
                    ><i data-feather="facebook"
                      ><vue-feather type="facebook"></vue-feather></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/akademi_sepakbola/"
                    target="_blank"
                    ><i data-feather="instagram"
                      ><vue-feather type="instagram"></vue-feather></i
                  ></a>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
export default {
  name: "login",
  data() {
    return {
      message: "",
      loading: false,
      type: "password",
      email: "admin@admin.com",
      password: "xyber!@#",
      submitted: false,
      number: "",
      min: 1000,
      max: 9999,
    };
  },

  computed: {
    loggedIn() {
      //   let user = localStorage.getItem('user');
      //   if(user){
      //   router.push('/');
      //   }
      // return this.$store.state.auth.status.loggedIn;
    },
  },

  created() {
    let user = localStorage.getItem("user");
    let token = localStorage.getItem("token");
    if (user && token) {
      router.push("/");
    }
    this.getRandomNumber();
    // this.$store.dispatch('authentication/logout');
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const user = {
        email: this.email,
        password: this.password,
      };
      if (this.email && this.password) {
        this.loading = true;
        this.$store.dispatch("auth/login", user).then(
          () => {
            router.push("/");
            console.log("success");
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.message = error.response.data.meta.message;
          }
        );
      }
    },

    getRandomNumber() {
      this.number = this.generateNumber();
    },
    generateNumber() {
      return Math.floor(Math.random() * (this.max - this.min + 1) + this.min);
    },

    closeAlert() {
      this.message = "";
    },

    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
  },
};
</script>

<style scoped>
.bgimage {
  background-color: #1e5448;
  background-image: url("../assets/images/bg.png");
  background-size: cover;
}
</style>
